<template>
  <v-navigation-drawer permanent class="sideMenu">
    <v-list>
      <v-list-item v-if="user" class="user-list-item">
        <div class="d-flex align-center">
          <v-avatar class="user-avatar" @click="$router.push('/UserDashboard')">
            <img :src="user.photoURL" />
          </v-avatar>
          <div>
            <v-list-item-title style="color: #030f18; font-size: medium">{{ user.name }}</v-list-item-title>
            <v-list-item-subtitle style="text-decoration: underline; color: #030f18; font-size: small">{{ user.email }}</v-list-item-subtitle>
            <v-list-item-subtitle style="color: #030f18; font-size: small">{{ user.tier }}</v-list-item-subtitle>
          </div>
        </div>
      </v-list-item>
    </v-list>
    <v-divider thickness="7"></v-divider>
    <v-list-item-group>
      <v-list-item v-for="(item, index) in filteredMenuItemsSide" :key="index" :to="item.link" link>
        <v-list-item-title style="display: flex; align-items: center">
          <svg-icon type="mdi" :path="item.icon" style="margin-right: 8px"></svg-icon>
          <div style="text-decoration: underline; color: #030f18; font-size: small">
            <span>
              {{ item.title }}
            </span>
          </div>
        </v-list-item-title>
      </v-list-item>
    </v-list-item-group>
    <v-footer class="text-center d-flex flex-column">
      <div>
        <v-btn-icon v-for="item in footerIcons" :key="item.icon" :icon="item.icon" :to="item.link"></v-btn-icon>
      </div>
      <div style="text-decoration: underline; color: #030f18; font-size: x-small">
        Todos los derechos reservados {{ new Date().getFullYear() }} —
        <strong>AMIChile</strong>
      </div>
      <v-divider></v-divider>
    </v-footer>
  </v-navigation-drawer>
</template>
<script>
import { useCounterStore } from "@/stores/counter";
import { useAuthStore } from "@/stores/auth";
import { computed } from "vue";
import SvgIcon from "@jamescoyle/vue-icon";

export default {
  components: {
    SvgIcon,
  },
  setup() {
    const counterStore = useCounterStore();
    const authStore = useAuthStore();
    const user = authStore.user;
    const filteredMenuItemsSide = computed(() => counterStore.filteredMenuItemsSide); // Make sure this computed property exists in your store

    const footerIcons = [
      { icon: counterStore.icons.mdiFacebook, link: "https://www.facebook.com" },
      { icon: counterStore.icons.mdiTwitter, link: "https://www.twitter.com" },
      { icon: counterStore.icons.mdiLinkedin, link: "https://www.linkedin.com" },
      { icon: counterStore.icons.mdiInstagram, link: "https://www.instagram.com" },
    ];
    return {
      filteredMenuItemsSide,
      footerIcons,
      user,
    };
  },
};
</script>
<style>
.sideMenu.sideMenu {
  background: radial-gradient(circle 500px at bottom right,white, #06e2e2 );
}
</style>
