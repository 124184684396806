<template>
  <v-dialog class="default-dialog" v-model="authStore.showLoginWithEmailPopup">
    <v-card>
      <v-card-text>
        <v-form>
          <v-card-title class="text-h5">
            Crea una cuenta usando tu correo o inicia sesión en una cuenta existente
          </v-card-title>
          <v-text-field
            v-model="email"
            :rules="[(v) => !!v || 'E-mail is required']"
            required
            density="compact"
            placeholder="Email address"
            prepend-inner-icon="mdi-email-outline"
            variant="outlined"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :rules="[(v) => !!v || 'Se requiere una contraseña']"
            required
            :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
            :type="visible ? 'text' : 'password'"
            density="compact"
            placeholder="password"
            prepend-inner-icon="mdi-lock-outline"
            variant="outlined"
            @click:append-inner="visible = !visible"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="authStore.toggleLoginWithEmailPopup(false)">Cerrar</v-btn>
        <v-btn @click="handleSignUpWithEmail">Crear cuenta</v-btn>
        <v-btn @click="handleLoginWithEmail">Iniciar sesion</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from "vue";
import { useAuthStore } from "@/stores/auth";
export default {
  name: "LoginWithEmailPopup",

  setup() {
    const email = ref("");
    const password = ref("");
    const authStore = useAuthStore();
    const visible = ref(false);

    const handleSignUpWithEmail = async () => {
      await authStore.signUpWithEmail(email.value, password.value);

      // Ensure authStore.user.uid is set before proceeding
      if (authStore.user.uid) {
        authStore.toggleLoginPopup(false); // Close login popup
        authStore.toggleAdditionalDataPopup(true); // Open additional data popup
      }
    };
    const handleLoginWithEmail = async () => {
      await authStore.signInWithEmail(email.value, password.value);
      authStore.toggleLoginWithEmailPopup(false);
    };
    return {
      email,
      password,
      handleLoginWithEmail,
      handleSignUpWithEmail,
      authStore,
      visible,
    };
  },
};
</script>
