<template>
  <div class="btn-container">
    <v-btn
      v-for="(item, index) in filteredMenuItemsTab"
      :key="index"
      :to="item.link"
      elevation="0"
      @click="activeTab = index"
      class="btn"
      :class="activeTab === index ? 'active-btn' : 'inactive-btn'"
    >
      <span class="btn-text">{{ item.title }}</span>
    </v-btn>
  </div>
</template>

<script>
import { computed } from "vue";
import { useCounterStore } from "@/stores/counter";
import { ref } from "vue";

export default {
  name: "SubmenuTab",
  setup() {
    const counterStore = useCounterStore();
    const filteredMenuItemsTab = computed(() => counterStore.filteredMenuItemsTab);
    const activeTab = ref(0);

    return {
      filteredMenuItemsTab,
      activeTab,
    };
  },
};
</script>

<style scoped>
.btn-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: left;
}

.btn {
  flex: 1 1 auto;
  color: var(--primary-color);
  background-color: transparent;
  white-space: normal;
  text-align: center;
  box-shadow: none;
  border-radius: 0;
  max-width: 50vw;
  margin-left: 5px;
  margin-left: 5px;

}

.btn-text {
  word-wrap: break-word;
  font-size: x-small;
  margin: 5px;
  font-weight: bold;
}

.inactive-btn {
  border-bottom: 1px solid gray;



}

.active-btn {
  border-bottom: 3px solid var(--primary-color);
  background: linear-gradient(to bottom, #06e2e2 0%, #06e2e2 10%, white 70%, white 100%);






}

</style>