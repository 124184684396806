import { defineStore } from "pinia";
import {
  db,
  getDoc,
  doc,
  addDoc,
  setDoc,
  collection,
  serverTimestamp,
  updateDoc,
  getDocs,
  query,
  where,
  runTransaction,
} from "@/main.js"; // Ensure this path matches your Firebase setup
import { useAuthStore } from "@/stores/auth";
import { useCounterStore } from "@/stores/counter";

export const useDataStore = defineStore("data", {
  state: () => ({
    // Add your state properties here if needed
    comunasList: [],
    userEventsData: [],
    userCentrosData: [],
    userCiclosProductivosData: [],
    userActivosData: [],
    userActivosTypes: [],
    customCostSubGroup: [],
    datosPlanctonPSMB: [],
    totalesMitilidosCosechaDash: [],
    totalesMitilidosSiembraDash: [],
  }),
  getters: {
    customCostSubGroup() {
      const authStore = useAuthStore();
      if (!authStore.userEventData) {
        return [];
      }
      const customSubGroups = authStore.userEventData
        .filter(
          (event) =>
            event.categoriaLabel === "Eventos de Operacion" &&
            event.subGrupoLabel === "nan" &&
            event.eventoLabel === "Otros Costos Generales"
        )
        .map((event) => event.subGroupName);
      return customSubGroups.length ? customSubGroups : [];
    },

    getCiclosProductivosByCentro: (state) => (centro) => {
      return state.userCiclosProductivosData.filter((ciclo) => ciclo.centro === centro);
    },
  },
  actions: {
    async fetchDataByReference(coleccion, referenciaData) {
      console.time("fetchDataByReference Total Time");

      const indexDocRef = doc(db, coleccion, "index_document");

      console.time("Index Document Fetch Time");
      const indexDocSnapshot = await getDoc(indexDocRef);
      console.timeEnd("Index Document Fetch Time");

      if (indexDocSnapshot.exists()) {
        const indexData = indexDocSnapshot.data();
        const userRUT = referenciaData.split("-")[0]; // Assumes referenciaData is a RUT-like string

        // First check for an exact match
        if (Object.prototype.hasOwnProperty.call(indexData, referenciaData)) {
          const exactMatchDocumentName = indexData[referenciaData];
          console.log(`Exact document name found in index: ${exactMatchDocumentName}`);
          const exactMatchDocRef = doc(db, coleccion, exactMatchDocumentName);
          const exactMatchDocSnapshot = await getDoc(exactMatchDocRef);
          if (exactMatchDocSnapshot.exists()) {
            const exactMatchData = exactMatchDocSnapshot.data();
            if (Object.prototype.hasOwnProperty.call(exactMatchData, referenciaData)) {
              const matchingObject = exactMatchData[referenciaData];
              console.log("Found exact matching object:", matchingObject);
              this.closestData = matchingObject;
              console.timeEnd("fetchDataByReference Total Time");
              return matchingObject;
            }
          }
        }

        // If no exact match, find the closest starting point that does not exceed the user's indicator
        let closestStartPoint = null;
        for (const startPoint in indexData) {
          if (startPoint <= userRUT && (closestStartPoint === null || startPoint > closestStartPoint)) {
            closestStartPoint = startPoint;
          }
        }

        if (closestStartPoint !== null) {
          const closestDocumentName = indexData[closestStartPoint];
          console.log(`Closest document name found in index: ${closestDocumentName}`);
          const closestDataDocRef = doc(db, coleccion, closestDocumentName);
          const closestDataDocSnapshot = await getDoc(closestDataDocRef);

          if (closestDataDocSnapshot.exists()) {
            const closestData = closestDataDocSnapshot.data();
            console.time("Data Matching Time");
            if (Object.prototype.hasOwnProperty.call(closestData, referenciaData)) {
              const matchingObject = closestData[referenciaData];
              console.log("Found matching object:", matchingObject);
              this.closestData = matchingObject;
              console.timeEnd("Data Matching Time");
              console.timeEnd("fetchDataByReference Total Time");
              return matchingObject;
            } else {
              console.log("No matching object found in closest data document");
            }
            console.timeEnd("Data Matching Time");
          } else {
            console.log("Closest data document does not exist");
          }
        } else {
          console.log("No starting point found in index that does not exceed the user RUT");
        }
      } else {
        console.log("Index document does not exist");
      }
      console.timeEnd("fetchDataByReference Total Time");
      return null; // Return null if no matching data is found
    },
    async fetchCentrosPerRut(rut, dataTable = "Mitilidos_CCAA") {
      const docRef = doc(db, dataTable, "unique_dict");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (Object.prototype.hasOwnProperty.call(data, rut)) {
          const centrosPorRut = Array.isArray(data[rut]) ? data[rut] : [data[rut]];
          const authStore = useAuthStore();
          authStore.user.centrosPorRut = centrosPorRut;
          console.log("Centros found for RUT:", rut, centrosPorRut);
          return centrosPorRut;
        } else {
          console.log("No data found for RUT:", rut);
          return [];
        }
      }
      return [];
    },
    async fetchDatosPlanctonPSMB() {
      try {
        const docRef = doc(db, "Datos_Plancton_PSMB", "Promedios");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          console.log("Datos_Plancton_PSMB data:", docSnap.data());
          this.datosPlanctonPSMB = docSnap.data();
          return this.datosPlanctonPSMB;
        }
        return null;
      } catch (error) {
        const counterStore = useCounterStore();
        counterStore.logError(error);
      }
    },
    async fetchTotalesMitilidosCosechaDash() {
      try {
        const docRef = doc(db, "Mitilidos_Cosecha_Dash", "totales");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          console.log("Total_Mitilidos_Cosecha_Dash data:", docSnap.data());
          this.totalesMitilidosCosechaDash = docSnap.data();
          return this.totalesMitilidosCosechaDash;
        }
        return null;
      } catch (error) {
        const counterStore = useCounterStore();
        counterStore.logError(error);
      }
    },
    async fetchTotalesMitilidosSiembraDash() {
      try {
        const docRef = doc(db, "Mitilidos_Siembra_Dash", "totales");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          console.log("Total_Mitilidos_Siembra_Dash data:", docSnap.data());
          this.totalesMitilidosSiembraDash = docSnap.data();
          return this.totalesMitilidosSiembraDash;
        }
        return null;
      } catch (error) {
        const counterStore = useCounterStore();
        counterStore.logError(error);
      }
    },

    async fetchComunas() {
      const comunaRef = doc(db, "Mitilidos_CCAA", "comunas");
      const comunaSnap = await getDoc(comunaRef);
      if (comunaSnap.exists()) {
        this.comunasList = comunaSnap.data().comunas; // Matches the state name
      } else {
        console.error("No comunas found");
      }
    },

    async submitSurveyData(response) {
      const authStore = useAuthStore();
      const userUid = authStore.user.uid;
      const surveyId = response.surveyId;
      const surveyName = response.surveyName;
      const subCollectionName = `${surveyName}.${surveyId}`;
      try {
        const surveyRef = doc(db, "Respuestas_Encuesta", "Respuestas");
        const responseRef = collection(surveyRef, subCollectionName);
        const docRef = await addDoc(responseRef, {
          ...response,
          uid: userUid,
          timestamp: serverTimestamp(),
        });
        console.log("Response document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding response document: ", e);
      }
    },

    async submitEventData(eventData) {
      const authStore = useAuthStore();
      const userUid = authStore.user.uid;
      const userEmail = authStore.user.email;

      if (!authStore.user.evento_subcollection) {
        const subcollectionName = `${userEmail}.${userUid}`;
        authStore.user.evento_subcollection = subcollectionName;
      }
      const subCollectionName = authStore.user.evento_subcollection;

      try {
        const eventCollection = doc(db, "Eventos", "Datos");
        const eventRef = collection(eventCollection, subCollectionName);

        const docRef = await addDoc(eventRef, {
          ...eventData,
          uid: userUid,
          timestamp: serverTimestamp(),
        });
        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    },

    async submitCentroData(centroData) {
      const authStore = useAuthStore();
      const userUid = authStore.user.uid;
      const userEmail = authStore.user.email;

      if (!authStore.user.centros_subcollection) {
        const subcollectionName = `${userEmail}.${userUid}`;
        authStore.user.centros_subcollection = subcollectionName;
      }
      const subCollectionName = authStore.user.centros_subcollection;

      try {
        const centrosCollection = doc(db, "Centros_Creados", "Centros");
        const eventRef = collection(centrosCollection, subCollectionName);

        const q = query(eventRef, where("codigo", "==", centroData.codigo));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty || authStore.user.centrosCreados.includes(centroData.codigo)) {
          alert("El codigo de centro utilizado ya tiene un centro creado.");
          return;
        }
        const centroDocRef = doc(centrosCollection, subCollectionName, centroData.codigo.toString());

        await setDoc(centroDocRef, {
          ...centroData,
          uid: userUid,
          timestamp: serverTimestamp(),
        });

        console.log("Document written with ID: ", centroData.codigo);
        alert("Centro creado exitosamente");
        // Fetch the updated centros data
        await this.fetchCentrosData();
        const counterStore = useCounterStore();
        counterStore.showCreateCentro = false;
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    },

    async submitCicloProductivoData(cicloProductivo) {
      const authStore = useAuthStore();
      const userUid = authStore.user.uid;
      const userEmail = authStore.user.email;

      if (!authStore.user.cicloProductivo_subcollection) {
        const subcollectionName = `${userEmail}.${userUid}`;
        authStore.user.cicloProductivo_subcollection = subcollectionName;
      }
      const subCollectionName = authStore.user.cicloProductivo_subcollection;

      try {
        const centrosCollection = doc(db, "Ciclos_Productivos", "Ciclos");
        const eventRef = collection(centrosCollection, subCollectionName);
        const docRef = await addDoc(eventRef, {
          ...cicloProductivo,
          uid: userUid,
          timestamp: serverTimestamp(),
        });

        console.log("Document written with ID: ", docRef.id);
        alert("Ciclo creado exitosamente");
        const counterStore = useCounterStore();
        counterStore.showCreateCicloProductivo = false;
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    },

    async submitAssetData(activo) {
      const authStore = useAuthStore();
      const userUid = authStore.user.uid;
      const userEmail = authStore.user.email;

      if (!authStore.user.activos_subcollection) {
        const subcollectionName = `${userEmail}.${userUid}`;
        authStore.user.activos_subcollection = subcollectionName;
      }
      const subCollectionName = authStore.user.activos_subcollection;

      try {
        const centrosCollection = doc(db, "Activos", "Activos");
        const eventRef = collection(centrosCollection, subCollectionName);
        const docRef = await addDoc(eventRef, {
          ...activo,
          uid: userUid,
          timestamp: serverTimestamp(),
        });

        console.log("Document written with ID: ", docRef.id);
        alert("Activo creado exitosamente");
        const counterStore = useCounterStore();
        counterStore.showCreateAsset = false;
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    },

    async fetchEventData() {
      const authStore = useAuthStore();
      const userEmail = authStore.user.email;
      const userUid = authStore.user.uid;
      const subCollectionName = `${userEmail}.${userUid}`;

      const eventCollection = doc(db, "Eventos", "Datos");
      const eventRef = collection(eventCollection, subCollectionName);

      try {
        const querySnapshot = await getDocs(eventRef);
        this.userEventsData = querySnapshot.docs.map((doc) => doc.data());
        console.log("Fetched event data:", this.userEventsData);
        return this.userEventsData;
      } catch (e) {
        console.error("Error fetching event data:", e);
        return [];
      }
    },

    async fetchCentrosData() {
      const authStore = useAuthStore();
      const userEmail = authStore.user.email;
      const userUid = authStore.user.uid;
      const subCollectionName = `${userEmail}.${userUid}`;

      const eventCollection = doc(db, "Centros_Creados", "Centros");
      const eventRef = collection(eventCollection, subCollectionName);

      try {
        const querySnapshot = await getDocs(eventRef);
        this.userCentrosData = querySnapshot.docs.map((doc) => doc.data());
        // Update centrosCreados in authStore
        const centrosCreados = this.userCentrosData.map((centro) => centro.codigo);
        authStore.user.centrosCreados = centrosCreados;
        await authStore.syncUserData();
        console.log("Fetched centros data:", this.userCentrosData);
        return this.userCentrosData;
      } catch (e) {
        console.error("Error fetching event data:", e);
        return [];
      }
    },

    async fetchCiclosProductivosData() {
      const authStore = useAuthStore();
      const userEmail = authStore.user.email;
      const userUid = authStore.user.uid;
      const subCollectionName = `${userEmail}.${userUid}`;

      const eventCollection = doc(db, "Ciclos_Productivos", "Ciclos");
      const eventRef = collection(eventCollection, subCollectionName);

      try {
        const querySnapshot = await getDocs(eventRef);
        this.userCiclosProductivosData = querySnapshot.docs.map((doc) => doc.data());
        // Update centrosCreados in authStore
        const ciclosProductivos = this.userCiclosProductivosData.map((ciclo) => ciclo.nombre);
        authStore.user.ciclosProductivos = ciclosProductivos;
        await authStore.syncUserData();
        console.log("Fetched ciclos productivos data:", this.userCiclosProductivosData);
        return this.userCiclosProductivosData;
      } catch (e) {
        console.error("Error fetching event data:", e);
        return [];
      }
    },

    async fetchUserData(uid) {
      const userDocRef = doc(db, "Usuarios", uid);
      try {
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          console.log("User data:", docSnap.data());
          return docSnap.data();
        } else {
          console.log(`Usuario ${uid} no encontrado en la colección Usuarios`);
          return null;
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        return null;
      }
    },
    async fetchAssetTypes () {

      const authStore = useAuthStore();
      const userEmail = authStore.user.email;
      const userUid = authStore.user.uid;
      const subCollectionName = `${userEmail}.${userUid}`;

      const assetTypesDocRef = doc(
          db,
          'Activos',
          'Activos',
          subCollectionName,
          'Tipos de Activos'
      );
      const assetTypesDoc = await getDoc(assetTypesDocRef);
      if (assetTypesDoc.exists()) {
          this.userActivosTypes = assetTypesDoc.data().types || [];
      }
    },
    async fetchAssetsData() {
      const authStore = useAuthStore();
      const userEmail = authStore.user.email;
      const userUid = authStore.user.uid;
      const subCollectionName = `${userEmail}.${userUid}`;

      const eventCollection = doc(db, "Activos", "Activos");
      const eventRef = collection(eventCollection, subCollectionName);

      try {
        const querySnapshot = await getDocs(eventRef);
        this.userActivosData = querySnapshot.docs.map((doc) => doc.data());
        // Update centrosCreados in authStore
        // const activos = this.userActivosData.map((activo) => activo.nombre);
        // authStore.user.ciclosProductivos = activos;
        await authStore.syncUserData();
        console.log("Fetched activos data:", this.userActivosData);
        return this.userActivosData;
      } catch (e) {
        console.error("Error fetching activos data:", e);
        return [];
      }
    },



    async updateUserData(uid, name, value) {
      const userDocRef = doc(db, "Usuarios", uid);
      try {
        const docSnap = await getDoc(userDocRef);
        if (!docSnap.exists()) {
          console.log(`Usuario ${uid} no encontrado en la colección Usuarios`);
          return;
        }
        await updateDoc(userDocRef, {
          [name]: value,
        });
        console.log("Field updated successfully.");
      } catch (error) {
        console.error("Error updating user data:", error);
      }
    },

    async updateCentroData(centroCode, updatedData) {
      const authStore = useAuthStore();
      // const userUid = authStore.user.uid;
      const subCollectionName = authStore.user.centros_subcollection;

      try {
        const centrosCollection = doc(db, "Centros_Creados", "Centros");
        const centroRef = doc(collection(centrosCollection, subCollectionName), centroCode);

        await updateDoc(centroRef, {
          ...updatedData,
          lastUpdated: serverTimestamp(),
        });

        console.log("Centro data updated successfully");

        // Update local state
        const centroIndex = this.userCentrosData.findIndex((c) => c.codigo === centroCode);
        if (centroIndex !== -1) {
          this.userCentrosData[centroIndex] = {
            ...this.userCentrosData[centroIndex],
            ...updatedData,
          };
        }
      } catch (e) {
        console.error("Error updating centro data: ", e);
      }
    },

    async updateLineaInCentro(centroCodigo, updates) {
      console.log("Updating linea in centro", "centroCodigo:", centroCodigo, "updates:", updates);
      const authStore = useAuthStore();
      const userEmail = authStore.user.email;
      const userUid = authStore.user.uid;
      const subCollectionName = `${userEmail}.${userUid}`;
      const centroDocRef = doc(db, "Centros_Creados", "Centros", subCollectionName, centroCodigo.toString());

      try {
        await runTransaction(db, async (transaction) => {
          const centroSnapshot = await transaction.get(centroDocRef);
          if (!centroSnapshot.exists()) {
            throw new Error("Centro not found");
          }

          const centroData = centroSnapshot.data();
          const updatedLineas = centroData.lineas.map((linea) => {
            const lineaUpdates = updates.filter((update) => update.lineaUid === linea.lineaUid);
            if (lineaUpdates.length > 0) {
              let newTramos = [...linea.tramos];
              lineaUpdates.forEach((update) => {
                newTramos = this.updateTramos(
                  newTramos,
                  update.cicloProductivoName,
                  update.metroInicio,
                  update.metroFin
                );
              });
              linea.tramos = newTramos;
              linea.estado = this.calculateLineaEstado(newTramos);
            }
            return linea;
          });

          transaction.update(centroDocRef, { lineas: updatedLineas });
        });

        console.log("Lineas updated successfully");
      } catch (error) {
        console.error("Error updating lineas:", error);
        throw error;
      }
    },

    updateTramos(tramos, cicloProductivoName, metroInicio, metroFin) {
      return tramos.flatMap((tramo) => {
        if (tramo.cicloProductivo) return [tramo];
        if (tramo.metroInicio >= metroFin || tramo.metroFin <= metroInicio) return [tramo];

        const newTramos = [];
        if (tramo.metroInicio < metroInicio) {
          newTramos.push({
            metroInicio: tramo.metroInicio,
            metroFin: metroInicio,
            cicloProductivo: null,
          });
        }
        newTramos.push({
          metroInicio: Math.max(tramo.metroInicio, metroInicio),
          metroFin: Math.min(tramo.metroFin, metroFin),
          cicloProductivo: cicloProductivoName,
        });
        if (tramo.metroFin > metroFin) {
          newTramos.push({
            metroInicio: metroFin,
            metroFin: tramo.metroFin,
            cicloProductivo: null,
          });
        }
        return newTramos;
      });
    },

    calculateLineaEstado(tramos) {
      const allOccupied = tramos.every((tramo) => tramo.cicloProductivo !== null);
      const allVacant = tramos.every((tramo) => tramo.cicloProductivo === null);
      return allOccupied ? "ocupado" : allVacant ? "vacante" : "parcial";
    },

    // async updateLineaInCentro(centroCodigo, lineaUid, cicloProductivoName, metroInicio, metroFin) {
    //   console.log("Updating linea in centro", "centroCodigo:",centroCodigo, "lineaUid:",lineaUid, "cicloProductivoName:", cicloProductivoName, "metroInicio:", metroInicio, "metroFin:", metroFin);
    //   const authStore = useAuthStore();
    //   const userEmail = authStore.user.email;
    //   const userUid = authStore.user.uid;
    //   const subCollectionName = `${userEmail}.${userUid}`;
    //   const centroDocRef = doc(db, "Centros_Creados", "Centros", subCollectionName, centroCodigo.toString());

    //   const centroSnapshot = await getDoc(centroDocRef);
    //   if (centroSnapshot.exists()) {
    //     const centroData = centroSnapshot.data();
    //     const updatedLineas = centroData.lineas.map((linea) => {
    //       if (linea.lineaUid === lineaUid) {
    //         const newTramos = [];
    //         linea.tramos.forEach((tramo) => {
    //           if (tramo.cicloProductivo) {
    //             newTramos.push(tramo); // Keep tramos with existing cicloProductivo
    //           } else if (tramo.metroInicio >= metroFin || tramo.metroFin <= metroInicio) {
    //             newTramos.push(tramo); // No overlap
    //           } else {
    //             if (tramo.metroInicio < metroInicio) {
    //               newTramos.push({
    //                 metroInicio: tramo.metroInicio,
    //                 metroFin: metroInicio,
    //                 cicloProductivo: null,
    //               });
    //             }
    //             newTramos.push({
    //               metroInicio: Math.max(tramo.metroInicio, metroInicio),
    //               metroFin: Math.min(tramo.metroFin, metroFin),
    //               cicloProductivo: cicloProductivoName,
    //             });
    //             if (tramo.metroFin > metroFin) {
    //               newTramos.push({
    //                 metroInicio: metroFin,
    //                 metroFin: tramo.metroFin,
    //                 cicloProductivo: null,
    //               });
    //             }
    //           }
    //         });
    //         linea.tramos = newTramos;
    //         const allOccupied = newTramos.every((tramo) => tramo.cicloProductivo !== null);
    //         const allVacant = newTramos.every((tramo) => tramo.cicloProductivo === null);
    //         if (allOccupied) {
    //           linea.estado = "ocupado";
    //         } else if (allVacant) {
    //           linea.estado = "vacante";
    //         } else {
    //           linea.estado = "parcial";
    //         }
    //       }
    //       return linea;
    //     });

    //     await updateDoc(centroDocRef, { lineas: updatedLineas });
    //   } else {
    //     console.error("Centro not found");
    //   }
    // },
  },
});
