import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { es } from 'vuetify/locale';

const getCssVariable = (name) => getComputedStyle(document.documentElement).getPropertyValue(name).trim();



const vuetify = createVuetify({
  locale: {
    locale: 'es', // Set the default locale key
    messages: {
      es // Load Spanish locale messages
    }
  },
  components,
  directives,
  icons: {
    iconfont: 'mdi',
  },

  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
          primary: getCssVariable('--primary-color').trim(),
          secondary: getCssVariable('--secondary-color').trim(),
          third: getCssVariable('--third-color').trim(),
          alert: getCssVariable('--alert-color').trim(),
          contour: getCssVariable('--contour-color').trim(),
          background: getCssVariable('--background-color').trim(),
          surface: getCssVariable('--surface-color').trim(),
          accent: getCssVariable('--accent-color').trim(),
          error: getCssVariable('--error-color').trim(),
          info: getCssVariable('--info-color').trim(),
          success: getCssVariable('--success-color').trim(),
          warning: getCssVariable('--warning-color').trim(),
      },
      // dark theme setup if needed
    }
  },
});
export default vuetify;