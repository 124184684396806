<template>
  <v-app>
    <SideMenu v-if="!isMobile"></SideMenu>
    <v-main>
      <SubmenuTab></SubmenuTab>
      <router-view></router-view>
      <BottomMenu v-if="isMobile && !counterStore.showLoadingScreenPopup"></BottomMenu>
    </v-main>
    <LoginPopUp v-if="authStore.showLoginPopup | authStore.showAdditionalDataPopup" @close="showLoginPopup = false" />
    <AditionalDataPopup v-if="authStore.showAdditionalDataPopup" />
    <LoginWithEmailPopup v-if="authStore.showLoginWithEmailPopup" />
    <LoadingScreenPopup v-if="counterStore.showLoadingScreenPopup" />
  </v-app>
</template>

<script>
import { ref, watch, nextTick, computed, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { useCounterStore } from "@/stores/counter";
import { useDataStore } from "@/stores/data";
import { useEventStore } from "@/stores/event";
import LoginPopUp from "@/components/PopUps/LoginPopUp.vue";
import SubmenuTab from "@/components/SubmenuTab.vue";
import BottomMenu from "@/components/BottomMenu.vue";
// import AppBar from "@/components/AppBar.vue";
import SideMenu from "./components/SideMenu.vue";
import AditionalDataPopup from "@/components/PopUps/AdditionalDataPopup.vue";
import LoginWithEmailPopup from "@/components/PopUps/LoginWithEmailPopup.vue";
import LoadingScreenPopup from "@/components/PopUps/LoadingScreenPopup.vue";
export default {
  name: "App",
  components: {
    LoginPopUp,
    SubmenuTab,
    BottomMenu,
    // AppBar,
    SideMenu,
    AditionalDataPopup,
    LoginWithEmailPopup,
    LoadingScreenPopup,
  },
  setup() {
    const authStore = useAuthStore();
    const counterStore = useCounterStore();
    const dataStore = useDataStore();
    const eventStore = useEventStore();
    const router = useRouter();
    const currentRoute = useRoute();
    const user = authStore.user;
    const isMobile = ref(window.innerWidth <= 768 || window.matchMedia("(display-mode: standalone)").matches);
    const signOut = () => {
      authStore.UserSignOut();
    };



    const updateIsMobile = () => {
      isMobile.value = window.innerWidth <= 768 || window.matchMedia("(display-mode: standalone)").matches;
    };

    onMounted(() => {
      
      window.addEventListener("resize", updateIsMobile);
      updateIsMobile(); // Initial update
      setTimeout(() => {
        counterStore.showLoadingScreenPopup = false;
        console.log("Loading screen hidden");
      }, 3000)
    });

    onUnmounted(() => {
      window.removeEventListener("resize", updateIsMobile);
    });

    watch(
      () => currentRoute.path,
      async () => {
        console.log("Navigating to route:", currentRoute.path);
        await nextTick();
      }
    );

    const previousRouteName = computed(() => {
      const history = router.history.value;
      if (!history || !history.entries || history.index <= 0) {
        return "Inicio"; // Default value when there is no history
      }
      const fromRoute = history.entries[history.index - 1];
      return fromRoute ? fromRoute.name : "Inicio";
    });

    return {
      user,
      isMobile,
      authStore,
      counterStore,
      dataStore,
      eventStore,
      signOut,
      previousRouteName,
    };
  },
};
</script>
<style>
.user-avatar img {
  border-radius: 50%;
  width: 80%;
  height: auto;
}
</style>
