import { defineStore } from 'pinia';
// import { useDataStore } from '@/stores/data';

import { mdiSourceMerge, mdiCashMultiple, mdiCashEdit, mdiSetSplit, mdiSeedOutline, mdiAccountGroup, mdiLightningBolt, mdiTools, mdiWater, mdiTestTubeEmpty, mdiGasStation, mdiTestTube, mdiCartArrowDown } from "@mdi/js";

export const useEventStore = defineStore('event', {
  state: () => ({
    categoria: {
    linea: {
      categoriaLabel: 'Eventos de Linea',
      categoriaIcon: mdiSeedOutline,
    subGrupo: {
        semillero: {
          subGrupoLabel: 'Eventos de  Semillero',
          subGrupoIcon: mdiSeedOutline,
    evento: {
            instalacionColector: {
              eventoLabel: 'Instalacion colector',
              eventoIcon: mdiSeedOutline,
              inputs: [
                { name: 'tipoColector', type: 'Select', inputLabel: "Tipo de colector", options: ["Malla Pecera", "Malla Raschel", "Cabo Mussel"], dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'costoColector', type: 'Number', inputLabel: "Costo de colector ", dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'costoPotala', type: 'Number', inputLabel: "Costo de potala", dataStructure: {dataCategory: "Costo", dataGroup: "Costo Insumos", dataSubGroup: "Costo Insumos", dataSubGroup2: "nan"} },
                { name: 'costoChicote', type: 'Number', inputLabel: "Costo de chicote", dataStructure: {dataCategory: "Costo", dataGroup: "Costo Insumos", dataSubGroup: "Costo Insumos", dataSubGroup2: "nan"} },
                { name: 'costoMallaPotala', type: 'Number', inputLabel: "Costo de malla para potala", dataStructure: {dataCategory: "Costo", dataGroup: "Costo Insumos", dataSubGroup: "Costo Insumos", dataSubGroup2: "nan"} },
                { name: 'largoColectorMallaAnchovetera', type: 'Number', inputLabel: "Largo colector de malla anchovetera (metros)", condition: {inputName: "tipoColector", value: 'Malla Pecera'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'largoColectorNylon', type: 'Number', inputLabel: "Largo colector de nylon (metros)", condition: {inputName: "tipoColector", value: 'Malla Raschel'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'anchoColector', type: 'Number', inputLabel: "Ancho colector (metros)", condition: {inputName: "tipoColector", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
              ],
              firestorePath: 'eventos',
            },
            muestreoColector: {
              eventoLabel: 'Muestreo Colector',
              eventoIcon: mdiTestTube,
              inputs: [
                { name: 'tipoMuestreoColector', type: 'Select', inputLabel: "Tipo Muestreo", options: ["Interno", "Externo"], dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'externoMuestreoColector', type: 'Texto', inputLabel: "Muestreador", dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'costoMuestreoColector', type: 'Number', inputLabel: "Costo Muestreo", dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo An�lisis", dataSubGroup2: "nan"} },
                { name: 'calibre', type: 'Number', inputLabel: "Calibre (mm)", dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'densidad', type: 'Number', inputLabel: "Densidad (unidades/metro)", condition: {inputName: "calibre", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'porcentajeMitilidos', type: 'Number', inputLabel: "Porcentaje de Chorito", condition: {inputName: "densidad", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'faunaAcompanante', type: 'Number', inputLabel: "Porcentaje Acompanante", condition: {inputName: "porcentajeMitilidos", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
              ],
              firestorePath: 'eventos',
            },
            retiroColector: {
              eventoLabel: 'Retiro colector',
              eventoIcon: mdiSeedOutline,
              inputs: [
                { name: 'costoRetiroColector', type: 'Number', inputLabel: "Costo Retiro", dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Retiro Colector", dataSubGroup2: "nan"} },
                { name: 'cantColectores', type: 'Number', inputLabel: "Cantidad Colectores Retirados", condition: {inputName: "formatoVenta", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'pesoColectores', type: 'Number', inputLabel: "Peso Colectores retirados (kg)", condition: {inputName: "cantColectores", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'calibrePromedio', type: 'Number', inputLabel: "Calibre Promedio (mm)", condition: {inputName: "pesoColectores", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
              ],
              firestorePath: 'eventos',
            },
            categorizacionSemillas: {
              eventoLabel: 'Categorizacion Semillas',
              eventoIcon: mdiSetSplit,
              inputs: [
                { name: 'costoCategorias', type: 'Number', inputLabel: "Costo Categorizacion", dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Categorizacion", dataSubGroup2: "nan"} },
                { name: 'semillaCategorias', type: 'Select', inputLabel: "Categorias", options: ["Metro Lineal", "Colector", "Granel"], dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'calibreMetroLineal', type: 'Number', inputLabel: "Calibre Metro Lineal (mm)", condition: {inputName: "semillaCategorias", value: 'Metro Lineal'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'kilosMetroLineal', type: 'Number', inputLabel: "Kilos Metro Lineal", condition: {inputName: "semillaCategorias", value: 'Metro Lineal'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'calibreColector', type: 'Number', inputLabel: "Calibre Colector (mm)", condition: {inputName: "semillaCategorias", value: 'Colector'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'kilosColector', type: 'Number', inputLabel: "Kilos Colector", condition: {inputName: "semillaCategorias", value: 'Colector'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'calibreGranel', type: 'Number', inputLabel: "Calibre Granel (mm)", condition: {inputName: "semillaCategorias", value: 'Granel'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'kilosGranel', type: 'Number', inputLabel: "Kilos Granel", condition: {inputName: "semillaCategorias", value: 'Granel'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
              ],
              firestorePath: 'eventos',
            },
            ventaSemilla: {
              eventoLabel: 'Venta Semilla',
              eventoIcon: mdiCashMultiple,
              inputs: [
                { name: 'trasladoVentaSemilla', type: 'Number', inputLabel: "Costo Traslado a Playa", dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Transporte", dataSubGroup2: "nan"} },
                { name: 'formatoVentaSemilla', type: 'Select', inputLabel: "Formato de Venta", options: ["Metro Lineal", "Colector", "Granel"], dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'kilosVentaSemilla', type: 'Number', inputLabel: "Semilla vendida (Kilos)", condition: {inputName: "formatoVentaSemilla", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "Venta", dataSubGroup: "Kilos Venta Semilla", dataSubGroup2: "nan"} },
                { name: 'cantColectoresVenta', type: 'Number', inputLabel: "Cantidad Colectores vendidos", condition: {inputName: "formatoVentaSemilla", value: 'Colector'}, dataStructure: {dataCategory: "Dato", dataGroup: "Venta", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'metrosVentaMetroLineal', type: 'Number', inputLabel: "Metros Linieales Semilla vendida (Metros Sembrados)", condition: {inputName: "formatoVentaSemilla", value: 'Metro Lineal'}, dataStructure: {dataCategory: "Dato", dataGroup: "Venta", dataSubGroup: "Metros Venta Semilla", dataSubGroup2: "nan"} },
                { name: 'totalVentaSemilla', type: 'Number', inputLabel: "Total venta en pesos", condition: {inputName: "formatoVentaSemilla", value: 'Any'}, dataStructure: {dataCategory: "Venta", dataGroup: "Venta Semilla", dataSubGroup: "Pesos Venta Semilla", dataSubGroup2: "nan"} },
                { name: 'cliente', type: 'Texto', inputLabel: "Cliente", condition: {inputName: "totalVentaSemilla", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'rutCliente', type: 'Number', inputLabel: "RUT Cliente", condition: {inputName: "totalVentaSemilla", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'facturaSemilla', type: 'Number', inputLabel: "N� de Factura", condition: {inputName: "totalVentaSemilla", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
              ],
              firestorePath: 'eventos',
            },
          },
          },
        engordador: {
          subGrupoLabel: 'Eventos de Engordador',
          subGrupoIcon: mdiSeedOutline,
    evento: {
            compraSemilla: {
              eventoLabel: 'Compra Semilla',
              eventoIcon: mdiCartArrowDown,
              inputs: [
                { name: 'formatoCompra', type: 'Select', inputLabel: "Formato de Compra", options: ["Metro Lineal", "Colector", "Granel"], dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'origenSemilla', type: 'Texto', inputLabel: "Localidad origen Semilla", dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'cantColectoresComprados', type: 'Number', inputLabel: "Cantidad Colectores comprados", condition: {inputName: "formatoCompra", value: 'Colector'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'cantSemilla', type: 'Number', inputLabel: "Kilos de Semilla Comprada", condition: {inputName: "formatoCompra", value: 'Granel'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'metrosSemilla', type: 'Number', inputLabel: "Metro Lineal de Semilla comprada", condition: {inputName: "formatoCompra", value: 'Metro Lineal'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'totalCompraSemilla', type: 'Number', inputLabel: "Total compra en pesos", condition: {inputName: "formatoCompra", value: 'Any'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Smilla", dataSubGroup2: "nan"} },
                { name: 'transporteCompraSemilla', type: 'Number', inputLabel: "Costo transporte ", condition: {inputName: "formatoCompra", value: 'Any'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Transporte", dataSubGroup2: "nan"} },
                { name: 'tipoTransporteCompraSemilla', type: 'Select', inputLabel: "Tipo Transporte", options: ["Interno", "Externo"], condition: {inputName: "transporteCompraSemilla", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'transportistaCompraSemilla', type: 'Texto', inputLabel: "Transportista", condition: {inputName: "transporteCompraSemilla", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'proveedor', type: 'Texto', inputLabel: "Proveedor", condition: {inputName: "totalCompraSemilla", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'rutProveedor', type: 'Number', inputLabel: "RUT Proveedor", condition: {inputName: "proveedor", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'facturaProveedor', type: 'Number', inputLabel: "N� de Factura", condition: {inputName: "proveedor", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
              ],
              firestorePath: 'eventos',
            },
            recepcionSemilla: {
              eventoLabel: 'Recepcion de semilla',
              eventoIcon: mdiSeedOutline,
              inputs: [
                { name: 'costoRecepcionColector', type: 'number', inputLabel: "Costo Recepcion Colector", dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Recepcion Colector", dataSubGroup2: "nan"} },
                { name: 'tipoSemilla', type: 'Select', inputLabel: "Tipo de Semilla", options: ["Metro Lineal", "Colector", "Granel"], dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'tipoColector', type: 'Select', inputLabel: "Tipo de colector", options: ["Malla Pecera", "Malla Raschel", "Cabo Mussel"], condition: {inputName: "tipoSemilla", value: 'Recien Captado, ~5mm'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'kilos', type: 'Number', inputLabel: "Kilos", condition: {inputName: "tipoSemilla", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'cantColectores', type: 'Number', inputLabel: "Cantidad Colectores", condition: {inputName: "tipoSemilla", value: 'Colector'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'porcentajeMitilidos', type: 'Number', inputLabel: "Porcentaje de Choritos", condition: {inputName: "tipoSemilla", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'unidadesPorMetro', type: 'Number', inputLabel: "Unidades por metro", condition: {inputName: "tipoSemilla", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
              ],
              firestorePath: 'eventos',
            },
            manejoSemilla: {
              eventoLabel: 'Siembra',
              eventoIcon: mdiSourceMerge,
              inputs: [
                { name: 'tipoManejo', type: 'Select', inputLabel: "Tipo de Manejo", options: ["Raleo", " Siembra"], dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'detalleManejo', type: 'Texto', inputLabel: "Notas Manejo", dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'costoManejo', type: 'Number', inputLabel: "Costo Manejo", dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Manejo", dataSubGroup2: "nan"} },
                { name: 'densidadSiembra', type: 'Number', inputLabel: "Densidad Siembra", condition: {inputName: "tipoManejo", value: 'Siembra'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'profundidad', type: 'Number', inputLabel: "Profundidad (metros)", condition: {inputName: "densidadSiembra", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'cantBoyasPorLinea', type: 'Number', inputLabel: "Cantidad Boyas por Linea", condition: {inputName: "profundidad", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'cantCabosporBoya', type: 'Number', inputLabel: "Cantidad Cabos por Boya", condition: {inputName: "cantBoyasPorLinea", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'costoSiembra', type: 'Number', inputLabel: "Costo Siembra", condition: {inputName: "densidadSiembra", value: 'Any'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Siembra", dataSubGroup2: "nan"} },
              ],
              firestorePath: 'eventos',
            },
            muestreoPeriodico: {
              eventoLabel: 'Muestreo Periodico',
              eventoIcon: mdiTestTubeEmpty,
              inputs: [
                { name: 'faunaAcompanante', type: 'Texto', inputLabel: "Fauna Acompanante", dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'porcentajeMitilidos', type: 'Number', inputLabel: "Porcentaje de Choritos", dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'calibre', type: 'Number', inputLabel: "Calibre (mm)", condition: {inputName: "porcentajeMitilidos", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'costoMuestreo', type: 'Number', inputLabel: "Costo Muestreo", condition: {inputName: "porcentajeMitilidos", value: 'Any'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo An�lisis", dataSubGroup2: "nan"} },
              ],
              firestorePath: 'eventos',
            },
            ventaChorito: {
              eventoLabel: 'Venta Chorito',
              eventoIcon: mdiCashMultiple,
              inputs: [
                { name: 'trasladoVentaChorito', type: 'Number', inputLabel: "Costo Traslado a Playa", dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Transporte", dataSubGroup2: "nan"} },
                { name: 'cantChorito', type: 'Number', inputLabel: "Toneladas Choritos Adultos  vendidos", dataStructure: {dataCategory: "Dato", dataGroup: "Venta", dataSubGroup: "Kilos Venta Chorito", dataSubGroup2: "nan"} },
                { name: 'totalVentaChorito', type: 'Number', inputLabel: "Total venta en pesos", condition: {inputName: "cantChorito", value: 'Any'}, dataStructure: {dataCategory: "Venta", dataGroup: "Venta Chorito", dataSubGroup: "Pesos Venta Chorito", dataSubGroup2: "nan"} },
                { name: 'rendimientoPreCosecha', type: 'Number', inputLabel: "Rendimiento ( Muestreo Pre-Cosecha)", condition: {inputName: "cantChorito", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "Venta", dataSubGroup: "Rendimiento Venta Chorito", dataSubGroup2: "nan"} },
                { name: 'choritosPreCosecha', type: 'Number', inputLabel: "Porcentaje Choritos ( Muestreo Pre-Cosecha)", condition: {inputName: "cantChorito", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "Venta", dataSubGroup: "Porcentaje Choritos ", dataSubGroup2: "nan"} },
                { name: 'rendimientoPlanta', type: 'Number', inputLabel: "Rendimiento ( Planta)", condition: {inputName: "cantChorito", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "Venta", dataSubGroup: "Rendimiento Venta Chorito (Planta)", dataSubGroup2: "nan"} },
                { name: 'choritoPlanta', type: 'Number', inputLabel: "Porcentaje Choritos ( Planta)", condition: {inputName: "cantChorito", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "Venta", dataSubGroup: "Porcentaje Choritos (Planta)", dataSubGroup2: "nan"} },
                { name: 'clienteChorito', type: 'Texto', inputLabel: "Cliente", condition: {inputName: "totalVentaChorito", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'rutClienteChorito', type: 'Number', inputLabel: "RUT Cliente", condition: {inputName: "totalVentaChorito", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'facturaVentaChorito', type: 'Number', inputLabel: "N� de Factura", condition: {inputName: "totalVentaChorito", value: 'Any'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
              ],
              firestorePath: 'eventos',
            },
          },
          },
        },
        },
    operacion: {
      categoriaLabel: 'Eventos de Operacion',
      categoriaIcon: mdiSeedOutline,
    subGrupo: {
        nan: {
          subGrupoLabel: 'nan',
          subGrupoIcon: 'nan',
    evento: {
            costoRRHH: {
              eventoLabel: 'Costos de RRHH',
              eventoIcon: mdiAccountGroup,
              inputs: [
                { name: 'tipoCostoRRHH', type: 'Select', inputLabel: "Tipo de costo de RRHH", options: ["Interno", "Externo"], dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'nombreColaborador', type: 'Texto', inputLabel: "Nombre del colaborador", condition: {inputName: "tipoCostoRRHH", value: 'Interno'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'rutColaborador', type: 'Texto', inputLabel: "RUT del colaborador", condition: {inputName: "tipoCostoRRHH", value: 'Interno'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'remuneracionFija', type: 'Number', inputLabel: "Costo Remuneracion Fija", condition: {inputName: "tipoCostoRRHH", value: 'Interno'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo RRHH", dataSubGroup2: "Costo RRHH Interno"} },
                { name: 'remuneracionVariable', type: 'Number', inputLabel: "Costo Remuneracion Variable", condition: {inputName: "tipoCostoRRHH", value: 'Interno'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo RRHH", dataSubGroup2: "Costo RRHH Interno"} },
                { name: 'costoRemuneracionExterna', type: 'Number', inputLabel: "Costo total ", condition: {inputName: "tipoCostoRRHH", value: 'Externo'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo RRHH", dataSubGroup2: "Costo RRHH Externo"} },
                { name: 'nombreProveedorRRHHExterna', type: 'Texto', inputLabel: "Nombre Proveedor RRHH Externa", condition: {inputName: "tipoCostoRRHH", value: 'Externo'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
              ],
              firestorePath: 'nan',
            },
            costoEnergiaElectrica: {
              eventoLabel: 'Costo Energia Electrica',
              eventoIcon: mdiLightningBolt,
              inputs: [
                { name: 'costoEE', type: 'Number', inputLabel: "Costo total Energia Electrica", dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Energia y Combustibles", dataSubGroup2: "nan"} },
              ],
              firestorePath: 'nan',
            },
            costoAgua: {
              eventoLabel: 'Costo Agua',
              eventoIcon: mdiWater,
              inputs: [
                { name: 'costoAgua', type: 'Number', inputLabel: "Costo Total Agua", dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Energia y Combustibles", dataSubGroup2: "Costo Agua"} },
              ],
              firestorePath: 'nan',
            },
            costoCombustible: {
              eventoLabel: 'Costo de Combustibles',
              eventoIcon: mdiGasStation,
              inputs: [
                { name: 'tipoCombustible', type: 'Select', inputLabel: "Tipo de combustible", options: ["Bencina", "Diesel", "Parafina", "Le�a"], dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'litrosBencina', type: 'Number', inputLabel: "Litros Bencina", condition: {inputName: "tipoCombustible", value: 'Bencina'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'costoBencinaTotal', type: 'Number', inputLabel: "Costo total Bencina", condition: {inputName: "tipoCombustible", value: 'Bencina'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Energia y Combustibles", dataSubGroup2: "Costo Bencina"} },
                { name: 'litrosDiesel', type: 'Number', inputLabel: "Litros Diesel", condition: {inputName: "tipoCombustible", value: 'Diesel'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'costoDieselTotal', type: 'Number', inputLabel: "Costo total Diesel", condition: {inputName: "tipoCombustible", value: 'Diesel'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Energia y Combustibles", dataSubGroup2: "Costo Diesel"} },
                { name: 'litrosParafina', type: 'Number', inputLabel: "Litros Parafina", condition: {inputName: "tipoCombustible", value: 'Parafina'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'costoParafinaTotal', type: 'Number', inputLabel: "Costo total Parafina", condition: {inputName: "tipoCombustible", value: 'Parafina'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Energia y Combustibles", dataSubGroup2: "Costo Parafina"} },
                { name: 'litrosLe�a', type: 'Number', inputLabel: "Kilos de Le�a", condition: {inputName: "tipoCombustible", value: 'Le�a'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'costoLe�aTotal', type: 'Number', inputLabel: "Costo total Le�a", condition: {inputName: "tipoCombustible", value: 'Le�a'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Energia y Combustibles", dataSubGroup2: "Costo Le�a"} },
              ],
              firestorePath: 'nan',
            },
            otrosCostosGenerales: {
              eventoLabel: 'Otros Costos Generales',
              eventoIcon: mdiCashEdit,
              inputs: [
                { name: 'categoriaCosto', type: 'Select', inputLabel: "Categoria de costo", options: ["Costo Operacional", "Costo Mantencion", "Costo Administracion", "Otros Costos"], dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'nombreCostoOperacional', type: 'Texto', inputLabel: "Nombre asignado al costo", condition: {inputName: "categoriaCosto", value: 'Costo Operacional'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Otros Costos", dataSubGroup2: "nan"} },
                { name: 'valorCostoOperacional', type: 'Number', inputLabel: "Valor asignado al costo", condition: {inputName: "categoriaCosto", value: 'Costo Operacional'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Otros Costos", dataSubGroup2: "nan"} },
                { name: 'nombreCostoMantencion', type: 'Texto', inputLabel: "Nombre asignado al costo", condition: {inputName: "categoriaCosto", value: 'Costo Mantencion'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Mantencion", dataSubGroup: "Otros Costos", dataSubGroup2: "nan"} },
                { name: 'valorCostoMantencion', type: 'Number', inputLabel: "Valor asignado al costo", condition: {inputName: "categoriaCosto", value: 'Costo Mantencion'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Mantencion", dataSubGroup: "Otros Costos", dataSubGroup2: "nan"} },
                { name: 'nombreCostoAdministracion', type: 'Texto', inputLabel: "Nombre asignado al costo", condition: {inputName: "categoriaCosto", value: 'Costo Administracion'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Administracion", dataSubGroup: "Otros Costos", dataSubGroup2: "nan"} },
                { name: 'valorCostoAdministracion', type: 'Number', inputLabel: "Valor asignado al costo", condition: {inputName: "categoriaCosto", value: 'Costo Administracion'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Administracion", dataSubGroup: "Otros Costos", dataSubGroup2: "nan"} },
                { name: 'nombreCostoOtros', type: 'Texto', inputLabel: "Nombre asignado al costo", condition: {inputName: "categoriaCosto", value: 'Otros Costos'}, dataStructure: {dataCategory: "Costo", dataGroup: "Otros Costos", dataSubGroup: "Otros Costos", dataSubGroup2: "nan"} },
                { name: 'valorCostoOtros', type: 'Number', inputLabel: "Valor asignado al costo", condition: {inputName: "categoriaCosto", value: 'Otros Costos'}, dataStructure: {dataCategory: "Costo", dataGroup: "Otros Cosos", dataSubGroup: "Otros Costos", dataSubGroup2: "nan"} },
              ],
              firestorePath: 'nan',
            },
            mantencion: {
              eventoLabel: 'Mantencion',
              eventoIcon: mdiTools,
              inputs: [
                // { name: 'mantencionActivo', type: 'Select', inputLabel: "Tipo de Activo", options:[], dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'mantencionActivo', type: 'Select', inputLabel: "Tipo de Activo", options: ["Plataforma", "Cosechadora", "Tamizadora", "Sembradora", "Motor Hidraulico", "Motor Fuera Borda"], dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'costoMantencionPlataforma', type: 'Number', inputLabel: "Costo Mantencion Plataforma", condition: {inputName: "mantencionActivo", value: 'Plataforma'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Mantencion", dataSubGroup: "Costo Mantencion", dataSubGroup2: "nan"} },
                { name: 'causaMantencionPlataforma', type: 'Texto', inputLabel: "Causa Mantencion Plataforma", condition: {inputName: "mantencionActivo", value: 'Plataforma'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'tipoMantencionPlataforma', type: 'Select', inputLabel: "Tipo mantencion", options: ["Interna", "Externa"], condition: {inputName: "mantencionActivo", value: 'Plataforma'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'externoMantencionPlataforma', type: 'Texto', inputLabel: "Ejecutor mantrencion", condition: {inputName: "tipoMantencionPlataforma", value: 'Externa'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'costoMantencionCosechadora', type: 'Number', inputLabel: "Costo Mantencion Cosechadora", condition: {inputName: "mantencionActivo", value: 'Cosechadora'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Mantencion", dataSubGroup: "Costo Mantencion", dataSubGroup2: "nan"} },
                { name: 'causaMantencionCosechadora', type: 'Texto', inputLabel: "Causa Mantencion Cosechadora", condition: {inputName: "mantencionActivo", value: 'Cosechadora'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'tipoMantencionCosechadora', type: 'Select', inputLabel: "Tipo mantencion", options: ["Interna", "Externa"], condition: {inputName: "mantencionActivo", value: 'Cosechadora'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'externoMantencionCosechadora', type: 'Texto', inputLabel: "Ejecutor mantrencion", condition: {inputName: "tipoMantencionCosechadora", value: 'Externa'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'costoMantencionTamizadora', type: 'Number', inputLabel: "Costo Mantencion Tamizadora", condition: {inputName: "mantencionActivo", value: 'Tamizadora'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Mantencion", dataSubGroup: "Costo Mantencion", dataSubGroup2: "nan"} },
                { name: 'causaMantencionTamizadora', type: 'Texto', inputLabel: "Causa Mantencion Tamizadora", condition: {inputName: "mantencionActivo", value: 'Tamizadora'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'tipoMantencionTamizadora', type: 'Select', inputLabel: "Tipo mantencion", options: ["Interna", "Externa"], condition: {inputName: "mantencionActivo", value: 'Tamizadora'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'externoMantencionTamizadora', type: 'Texto', inputLabel: "Ejecutor mantrencion", condition: {inputName: "tipoMantencionCosechadora", value: 'Externa'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'costoMantencionSembradora', type: 'Number', inputLabel: "Costo Mantencion Sembradora", condition: {inputName: "mantencionActivo", value: 'Sembradora'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'causaMantencionSembradora', type: 'Texto', inputLabel: "Causa Mantencion Sembradora", condition: {inputName: "mantencionActivo", value: 'Sembradora'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'tipoMantencionSembradora', type: 'Select', inputLabel: "Tipo mantencion", options: ["Interna", "Externa"], condition: {inputName: "mantencionActivo", value: 'Sembradora'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'externoMantencionSembradora', type: 'Texto', inputLabel: "Ejecutor mantrencion", condition: {inputName: "tipoMantencionSembradora", value: 'Externa'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'costoMantencionMotorHidraulico', type: 'Number', inputLabel: "Costo Mantencion Motor Hidraulico", condition: {inputName: "mantencionActivo", value: 'Motor Hidraulico'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Mantencion", dataSubGroup: "Costo Mantencion", dataSubGroup2: "nan"} },
                { name: 'causaMantencionMotorHidraulico', type: 'Texto', inputLabel: "Causa Mantencion Motor Hidraulico", condition: {inputName: "mantencionActivo", value: 'Motor Hidraulico'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'tipoMantencionMotorHidraulico', type: 'Select', inputLabel: "Tipo mantencion", options: ["Interna", "Externa"], condition: {inputName: "mantencionActivo", value: 'Motor Hidraulico'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'externoMantencionMotorHidraulico', type: 'Texto', inputLabel: "Ejecutor mantrencion", condition: {inputName: "tipoMantencionMotorHidraulico", value: 'Externa'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'costoMantencionMotorFueraBorda', type: 'Number', inputLabel: "Costo Mantencion Motor Fuera Borda", condition: {inputName: "mantencionActivo", value: 'Motor Fuera Borda'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Mantencion", dataSubGroup: "Costo Mantencion", dataSubGroup2: "nan"} },
                { name: 'causaMantencionMotorFueraBorda', type: 'Texto', inputLabel: "Causa Mantencion Motor Fuera Borda", condition: {inputName: "mantencionActivo", value: 'Motor Fuera Borda'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'tipoMantencionMotorFueraBorda', type: 'Select', inputLabel: "Tipo mantencion", options: ["Interna", "Externa"], condition: {inputName: "mantencionActivo", value: 'Motor Fuera Borda'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'externoMantencionMotorFueraBorda', type: 'Texto', inputLabel: "Ejecutor mantrencion", condition: {inputName: "tipoMantencionMotorFueraBorda", value: 'Externa'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Mantencion", dataSubGroup: "nan", dataSubGroup2: "nan"} },
              ],
              firestorePath: 'eventos',
            },
            permisos: {
              eventoLabel: 'Permisos',
              eventoIcon: mdiTools,
              inputs: [
                { name: 'permisosCategoria', type: 'Select', inputLabel: "Permisos para necesarios", options: ["PSMB", "Patente", "Otros"], dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'permisosPSMB', type: 'Number', inputLabel: "Costo PSMB", condition: {inputName: "permisosCategoria", value: 'PSMB'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Permisos y Patentes", dataSubGroup: "Costo Permisos y Patentes", dataSubGroup2: "nan"} },
                { name: 'permisosPatente', type: 'Number', inputLabel: "Costo Patente ", condition: {inputName: "permisosCategoria", value: 'Patente'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Permisos y Patentes", dataSubGroup: "Costo Permisos y Patentes", dataSubGroup2: "nan"} },
                { name: 'permisosOtros', type: 'Texto', inputLabel: "Otros", condition: {inputName: "permisosCategoria", value: 'Otros'}, dataStructure: {dataCategory: "Dato", dataGroup: "Costo Permisos y Patentes", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'costoPermisosOtros', type: 'Number', inputLabel: "Costo ", condition: {inputName: "permisosCategoria", value: 'Otros'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Permisos y Patentes", dataSubGroup: "Costo Permisos y Patentes", dataSubGroup2: "nan"} },
              ],
              firestorePath: 'eventos',
            },
            insumos: {
              eventoLabel: 'Insumos',
              eventoIcon: mdiTools,
              inputs: [
                { name: 'insumosSeleccion', type: 'Select', inputLabel: "Tipo de Insumo", options: ["Cabotaje", " Elementos de Proteccion Personal", "Boyas", "Manga Algodon"], dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'insumosSeleccionCabotaje', type: 'Select', inputLabel: "Tipo de Cabotaje", options: ["Fondeo", "Balsa", "Linea Madre"], condition: {inputName: "insumosSeleccion", value: 'Cabotaje'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'insumosCabotajeFondeo', type: 'Number', inputLabel: "Costo Cabotaje Fondeo", condition: {inputName: "insumosSeleccionCabotaje", value: 'Fondeo'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Insumos", dataSubGroup2: "nan"} },
                { name: 'insumosCabotajeFondeoMetros', type: 'Number', inputLabel: "Metros Cabotaje Fondeo", condition: {inputName: "insumosSeleccionCabotaje", value: 'Fondeo'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'insumosCabotajeBalsa', type: 'Number', inputLabel: "Costo Cabotaje Balsa", condition: {inputName: "insumosSeleccionCabotaje", value: 'Balsa'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Insumos", dataSubGroup2: "nan"} },
                { name: 'insumosCabotajeBalsaMetros', type: 'Number', inputLabel: "Metros Cabotaje Balsa", condition: {inputName: "insumosSeleccionCabotaje", value: 'Balsa'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'insumosCabotajeLineaMadre', type: 'Number', inputLabel: "Costo Cabotaje Linea Madre", condition: {inputName: "insumosSeleccionCabotaje", value: 'Linea Madre'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Insumos", dataSubGroup2: "nan"} },
                { name: 'insumosCabotajeLineaMadreMetros', type: 'Number', inputLabel: "Metros Cabotaje Linea Madre", condition: {inputName: "insumosSeleccionCabotaje", value: 'Linea Madre'}, dataStructure: {dataCategory: "Dato", dataGroup: "nan", dataSubGroup: "nan", dataSubGroup2: "nan"} },
                { name: 'insumosElementosProteccionPersonal', type: 'Number', inputLabel: "Costo Elementos de Proteccion Personal", condition: {inputName: "insumosSeleccion", value: 'Elementos de Proteccion Personal'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Insumos", dataSubGroup2: "nan"} },
                { name: 'insumosBoyas', type: 'Number', inputLabel: "Costo Boyas", condition: {inputName: "insumosSeleccion", value: 'Boyas'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Insumos", dataSubGroup2: "nan"} },
                { name: 'insumosMangaAlgodon', type: 'Number', inputLabel: "Costo Manga Algodon", condition: {inputName: "insumosSeleccion", value: 'Manga Algodon'}, dataStructure: {dataCategory: "Costo", dataGroup: "Costo Operacional", dataSubGroup: "Costo Insumos", dataSubGroup2: "nan"} },
              ],
              firestorePath: 'eventos',
            },
          },
          },
        },
        },
      },
    }),
    // getters: {
    //   mantencionActivoOptions: (state) => {
    //     const dataStore = useDataStore();
    //     state.categoria.operacion.subGrupo.nan.evento.mantencion.inputs.find(input => input.name === 'mantencionActivo').options = dataStore.userActivosTypes;
    //     return state.categoria.operacion.subGrupo.nan.evento.mantencion.inputs.find(input => input.name === 'mantencionActivo').options;
    //   }
    // }
    })
