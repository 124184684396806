<template>
  <v-dialog class="default-dialog" v-model="authStore.showAdditionalDataPopup">
    <v-card>
      <v-card-title class="white--text headline text-center bold">
        Por favor, completa tus datos para continuar
      </v-card-title>
      <v-divider :thickness="4"></v-divider>
      <v-card-text>
        <div class="pb-4">
          <!-- Added padding bottom -->
          <p>Solo deberás ingresarlos una vez</p>
          <p>Podrás actualizarlos cuando desees</p>
        </div>
      </v-card-text>

      <v-form ref="form" @submit.prevent="submitAdditionalData" style="border-radius: 10px">
        <v-text-field v-model="user.rut" :rules="[rutRule]" label="RUT"></v-text-field>
        <v-text-field v-model="user.razonSocial" :rules="[requiredRule]" label="Razón Social"></v-text-field>
        <v-text-field v-model="user.direccion" :rules="[requiredRule]" label="Direccion"></v-text-field>
        <v-text-field v-model="user.telContacto" :rules="[requiredRule]" label="Telefono de Contacto"></v-text-field>
        <v-text-field v-model="user.region" :rules="[requiredRule]" label="Region"></v-text-field>

        <v-select v-model="user.comuna" :items="comunasList" :rules="[requiredRule]" label="Comuna"></v-select>
        <v-row class="d-flex justify-center">
          <v-switch
            v-model="user.engorda"
            label="Es engordador?"
            class="custom-switch-label"
            style="margin-right: 13px"
          ></v-switch>
          <v-switch
            v-model="user.captura"
            label="Es capturador?"
            class="custom-switch-label"
            style="margin-right: 13px"
          ></v-switch>
          <v-switch
            v-model="user.procesado"
            label="Es procesador?"
            class="custom-switch-label"
            style="margin-right: 13px"
          ></v-switch>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-btn type="submit">Actualizar datos</v-btn>
        </v-row>
        <v-col>
          <br/>
          <v-divider :thickness="4"></v-divider>
          <v-divider :thickness="4"></v-divider>

          <v-card-title style="color: black">Acreditar documentos Sernapesca</v-card-title>
          <v-card-text style="color: black">
            <p>Para acreditar tus documentos, sube una foto de tu certificado de centro de cultivo</p>
            <p>
              Cuando hayas ingresado tu rut en el recuadro superior, un listado de los códigos de centros asociados a tu
              rut estarán disponibles para seleccionar, puedes seleccionar más de uno
            </p>
          </v-card-text>
          <v-row class="d-flex justify-center" no-gutters>
            <v-col cols="12">
              <v-combobox
                v-model="requestedCentro"
                :items="centrosList"
                label="Centro"
                chips
                clearable
                solo
              ></v-combobox>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="requestedCentroComments" label="Comentarios de la solicitud"></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn @click="fileInput.click()">Seleccionar Archivo</v-btn>
            <input type="file" ref="fileInput" @change="handleFileSelection" hidden />
            <v-btn @click="submitCentroValidationRequest" :disabled="!fileToUpload">
              Solicitar Validación de centro
              <v-progress-circular
                v-if="isSubmitting"
                indeterminate
                size="24"
                color="white"
                class="ml-2"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watchEffect, onMounted, computed } from "vue";
import { useDataStore } from "@/stores/data";
import { useAuthStore } from "@/stores/auth";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, collection, addDoc, serverTimestamp } from "@/main.js";

export default {
  name: "AditionalDataPopup",
  setup() {
    const dataStore = useDataStore();
    const authStore = useAuthStore();

    const user = computed(() => authStore.user);
    const form = ref(null);

    const fileUploaded = ref(false);
    const fileToUpload = ref(null);

    const centrosList = ref([]);
    const requestedCentro = ref("");
    const requestedCentroComments = ref("");
    const comunasList = computed(() => dataStore.comunasList);

    const fileInput = ref(null);

    const isSubmitting = ref(false);
    const requiredRule = (value) => (value ? true : "Campo requerido");

    watchEffect(() => {
      if (user.value.rut) {
        // Ensure there's a rut to process
        fetchCentrosForRut(user.value.rut);
      }
    });

    async function fetchCentrosForRut(rut) {
      centrosList.value = await dataStore.fetchCentrosPerRut(rut);
    }

    const submitAdditionalData = () => {
      if (user.value.rut && user.value.razonSocial && user.value.direccion && user.value.telContacto && user.value.region && user.value.comuna) {
        authStore.setAdditionalData({ ...user.value });
        console.log("Additional data submitted:", user.value);
        authStore.toggleAdditionalDataPopup(false);
        authStore.toggleLoginWithEmailPopup(false);
        alert("Datos guardados correctamente");
      } else {
        alert("Debes completar todos los campos");
      }
    };

    const handleFileSelection = (event) => {
      fileToUpload.value = event.target.files[0];
    };

    const submitCentroValidationRequest = async () => {
      if (!fileToUpload.value) {
        console.error("No file selected.");
        alert("Debes seleccionar un archivo");
        return;
      }
      if (!requestedCentro.value) {
        console.error("No centro selected.");
        alert("Debes seleccionar un centro");
        return;
      }

      isSubmitting.value = true;

      const storage = getStorage();
      const filePath = `Archivos_Solicitud_Validación_Centros/${authStore.user.uid}/${fileToUpload.value.name}_${requestedCentro.value}`;
      const fileRef = storageRef(storage, filePath);

      try {
        const uploadTask = await uploadBytes(fileRef, fileToUpload.value);
        const fileUrl = await getDownloadURL(uploadTask.ref);

        await addDoc(collection(db, "Solicitudes_Validación_Centros"), {
          requestedCentro: requestedCentro.value,
          requestedCentroComments: requestedCentroComments.value,
          timestamp: serverTimestamp(),
          status: "Pending",
          userId: authStore.user.uid,
          fileUrl: fileUrl,
        });

        console.log("Centro validation requested with file URL:", fileUrl);
        alert(
          "Solicitud de validacion de centro existosa, responderemos a tu solicitud a la brevedad.",
          requestedCentro.value
        );
        fileUploaded.value = true;
        fileToUpload.value = null; // Clear the file holder
      } catch (error) {
        console.error("Error during submission:", error);
      } finally {
        isSubmitting.value = false;
      }
    };
    const rutRule = (value) => {
      const result = authStore.validateRut(value, user.value);
      if (result === "RUT Correct") {
        return true; // Return true to pass the validation
      }
      return result; // Return error message
    };
    onMounted(async () => {
      if (dataStore.comunasList.length === 0) {
        await dataStore.fetchComunas();
      }
      user.value.procesado = false;
      user.value.engorda = false;
      user.value.captura = false;
    });

    return {
      authStore,
      fileUploaded,
      fileToUpload,
      handleFileSelection,
      submitCentroValidationRequest,
      submitAdditionalData,
      comunasList,
      user,
      requestedCentro,
      requestedCentroComments,
      fileInput,
      centrosList,
      rutRule,
      requiredRule,
      isSubmitting,
      form,
    };
  },
};
</script>

<style>
/* Your component styles here */
</style>
