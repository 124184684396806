<template>
  <v-dialog class="loading-dialog" v-model="counterStore.showLoadingScreenPopup" persistent max-height="100vh"
    height="100vh" max-width="100vw" width="100vw" margin="none">
    <v-card class="loading-card">
      <div class="loading-animation">
        <img src="@/assets/LoadingScreenIcon.png" alt="loading icon" class="loading-icon" />
      </div>

    </v-card>
  </v-dialog>
</template>

<script setup>
import { useCounterStore } from "@/stores/counter";

const counterStore = useCounterStore();
</script>



<style scoped>
.loading-dialog {
  background-color: rgba(0, 0, 0, 0.5) !important;
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
  z-index: 99999 !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

}

.loading-card {
  background: radial-gradient(circle 800px at center, white, #06e2e2) !important;
  /* Adjust this color to match your icon */
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 0px !important;
  z-index: 99999 !important;
}

.loading-animation {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

.loading-icon {
  width: 25vw !important;
  height: 25vw !important;
  /* Ensure the icon maintains a square aspect ratio */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  animation: pulsate 1s infinite !important;
}

.loading-header,
.loading-subheader {
  font-family: 'Open Sans', sans-serif !important;
  color: rgb(103, 101, 101) !important;
  text-align: center !important;

}

.loading-header {
  margin: 10px 0 !important;
  font-size: 1rem !important;
}

.loading-subheader {
  margin: 5px 0 !important;
  font-size: 0.5rem !important;
}



@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
