// stores/counter.js
import { defineStore } from "pinia";
import { db, getDocs, collection, getStorage, storageRef, getDownloadURL, addDoc } from "../main.js";

import { useAuthStore } from "@/stores/auth.js";
import {
  mdiFerry,
  mdiCurrencyUsd,
  mdiTools,
  mdiRss,
  mdiMap,
  mdiDownload,
  mdiAccountCircle,
  mdiAccountQuestionOutline,
  mdiInstagram,
  mdiLinkedin,
  mdiTwitter,
  mdiFacebook,
  mdiMenu,
  mdiDelete,
  mdiChevronLeft,
  mdiPencilOutline,
  mdiPlusCircle,
  mdiFinance,
  mdiStar,
  mdiStarOutline,
  mdiViewList,
  mdiHandshakeOutline,

} from "@mdi/js";

export const useCounterStore = defineStore("counter", {
  state: () => ({
    navigationItems: [
      { title: "Inicio", icon: mdiFerry, link: "/", minTier: "tourist", bottom: true, side: true, tab: false },
      { title: "Comunidad", icon: mdiRss, link: "/FeedView", minTier: "tourist", bottom: false, side: true, tab: false },
      { title: "Nuevo Evento", icon: mdiPlusCircle, link: "/NuevoEvento", minTier: "free", bottom: true, side: true, tab: false },
      { title: "Mapa", icon: mdiMap, link: "/MapView", minTier: "tourist", bottom: false, side: true, tab: false },
      { title: "Panel de Usuario", icon: mdiAccountCircle, link: "/UserDashboard", minTier: "tourist", bottom: true, side: true, tab: false },
      { title: "Visualizador de Data", icon: mdiViewList, link: "/DataViewer", minTier: "free", bottom: true, side: true, tab: false },

      { title: "Panel de Administración", icon: mdiTools, link: "/AdminDashboard", minTier: "admin", bottom: false, side: false, tab: false },
      { title: "Administrar Encuestas", icon: mdiAccountQuestionOutline, link: "/ManageSurveys", minTier: "admin", bottom: false, side: false, tab: true },
      { title: "Administrar Reportes", icon: mdiAccountQuestionOutline, link: "/ManageReports", minTier: "admin", bottom: false, side: false, tab: true },
      { title: "Administrar Usuarios", icon: mdiDownload, link: "/ManageUsers", minTier: "admin", bottom: false, side: false, tab: true },
      { title: "Administrar Proveedores", icon: mdiDownload, link: "/ManageProviders", minTier: "admin", bottom: false, side: false, tab: true },
      { title: "Crear Encuesta", icon: mdiAccountQuestionOutline, link: "/CreateSurvey", minTier: "admin", bottom: false, side: false, tab: false },

      { title: "Resultado Lote", icon: mdiCurrencyUsd, link: "/ResultadoLote", minTier: "free", bottom: false, side: false, tab: false },
      { title: "Reportes", icon: mdiDownload, link: "/PdfReports", minTier: "tourist", bottom: false, side: false, tab: false },
      { title: "Responder Encuestas", icon: mdiAccountQuestionOutline, link: "/SurveyList", minTier: "tourist", bottom: false, side: false, tab: false },
      { title: "Listado Proveedores", icon: mdiHandshakeOutline , link: "/ProvidersList", minTier: "free", bottom: false, side: true, tab: true },
      { title: "Reportes Historicos", icon: mdiFinance, link: "/HistoricalReport", minTier: "tourist", bottom: true, side: true, tab: true },
    ],
    icons: { mdiFerry, mdiCurrencyUsd, mdiTools, mdiRss, mdiMap, mdiDownload, mdiAccountCircle, mdiAccountQuestionOutline, mdiInstagram, mdiLinkedin, mdiTwitter, mdiFacebook, mdiMenu, mdiDelete, mdiChevronLeft, mdiPencilOutline, mdiStar, mdiStarOutline },
    // pdf reports for reports module, they are managed in state and updated from firebase Storage using actions
    reportFiles: [],
    // headers for reports files data table
    headers: [
      { title: "Nombre", value: "name" },
      { title: "Tipo", value: "tipo" },
      { title: "Fecha", value: "fecha" },
      { title: "Descargar", value: "action", sortable: false },
    ],
    // states for surveys
    surveys: [],
    // states for centros_ cosecha and centros_siembra
    centrosCosecha: [],
    centrosSiembra: [],
    // handle is loading to show loading popup on the screen for th ecomplete app
    showLoadingScreenPopup: true,
    showCreateCicloProductivo: false,
    showCreateCentro: false,
    showCreateAsset: false,
  }),
  actions: {
    setTabNavigationItems(titles) {
      this.navigationItems.forEach(item => {
        item.tab = titles.includes(item.title);
      });
    },
    async fetchReporFiles() {
      const querySnapshot = await getDocs(collection(db, "Informes"));
      this.reportFiles = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      // if documents creado exist, transform date from Timestamp to Date
      this.reportFiles.forEach((doc) => {
        if (doc.fecha) {
          doc.fecha = doc.fecha.toDate().toLocaleDateString();
        }
      });
    },
    async downloadFile(rawUrl) {
      try {
        const storage = getStorage();
        const fileRef = storageRef(storage, rawUrl);
        console.log('item url', rawUrl);
        console.log('fileRef', fileRef);
        const url = await getDownloadURL(fileRef);
        console.log('url', url);
        window.open(url, "_blank");
      } catch (error) {
        this.logError(error);
      }
    },

    async fetchSurveys() {
      const querySnapshot = await getDocs(collection(db, "Encuestas"));
      this.surveys = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(), // Transform createdAt to a Date object directly
      }));
    },

    refreshPWA() {
      // Simply reload the page to reflect all changes
      window.location.reload();
    },
    async logError(error) {
      try {
        const authStore = useAuthStore();
        const userInfo = authStore.user ? { uid: authStore.user.uid, email: authStore.user.email } : { uid: null, email: null };

        const stack = error.stack || "";
        const stackArray = stack.split("\n");
        const errorDetails = stackArray[1] ? stackArray[1].trim() : "No stack trace available";

        const logEntry = {
          error: error.message || error,
          userInfo: userInfo,
          browser: navigator.userAgent,
          device: navigator.platform,
          timestamp: new Date(),
          errorDetails: errorDetails,
        };

        await addDoc(collection(db, "Log"), logEntry);
      } catch (err) {
        console.error("Error logging error: ", err);
      }
    }


    
  },
  getters: {
    filteredMenuItemsTab(state) {
      const authStore = useAuthStore();
      const tierLevels = authStore.tierLevels;
      const userLevel = tierLevels[authStore.user.tier];

      return state.navigationItems.filter((item) => {
        const requiredLevel = tierLevels[item.minTier];
        return userLevel >= requiredLevel && item.tab;
      });
    },
    filteredMenuItemsBottom(state) {
      const authStore = useAuthStore();
      const tierLevels = authStore.tierLevels;
      const userLevel = tierLevels[authStore.user.tier];

      return state.navigationItems.filter((item) => {
        const requiredLevel = tierLevels[item.minTier];
        return userLevel >= requiredLevel && item.bottom;
      });
    },
    filteredMenuItemsSide(state) {
      const authStore = useAuthStore();
      const tierLevels = authStore.tierLevels;
      const userLevel = tierLevels[authStore.user.tier];

      return state.navigationItems.filter((item) => {
        const requiredLevel = tierLevels[item.minTier];
        return userLevel >= requiredLevel && item.side;
      });
    },

  },
});
