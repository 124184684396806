import { createApp } from 'vue'
import App from './App.vue'
import { initializeApp } from 'firebase/app'
import { getFirestore , collection, setDoc, addDoc , doc, getDoc, getDocs, updateDoc, where, query, serverTimestamp, deleteDoc, orderBy, writeBatch, runTransaction } from 'firebase/firestore'
import { getStorage, ref as storageRef, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage';
import router from './router.js'

// pinia
import { createPinia } from 'pinia'
import { useAuthStore } from './stores/auth'

// Vuetify
import vuetify from '@/vuetify.config.js'
// import 'vuetify/styles'
// import { createVuetify } from 'vuetify'
// import * as components from 'vuetify/components'
// import * as directives from 'vuetify/directives'

// scss
import './assets/styles/main.scss';
import './assets/styles/_variables.scss';
import VueGoogleMaps from '@fawmi/vue-google-maps';



// // set up translation to es
// const es = {
//   dataIterator: {
//     rowsPerPageText: 'Elementos por página:',
//     rowsPerPageAll: 'Todos',
//     pageText: '{0}-{1} de {2}',
//     noResultsText: 'No se encontraron datos',
//     nextPage: 'Página siguiente',
//     prevPage: 'Página anterior'
//   },
//   dataTable: {
//     itemsPerPageText: 'Filas por página'
//   },
//   noDataText: 'No hay datos disponibles',
// };

// const getCssVariable = (name) => getComputedStyle(document.documentElement).getPropertyValue(name);

// const vuetify = createVuetify({
//   components,
//   directives,
//   icons: {
//     iconfont: 'mdi', // This line configures Vuetify to use MDI icons
//   },
//   lang: {
//     locales: { es },
//     current: 'es',
//   },
//     theme: {
//     defaultTheme: 'light',
//     themes: {
//       light: {
//         colors: {
//           primary: getCssVariable('--primary-color').trim(),
//           secondary: getCssVariable('--secondary-color').trim(),
//           third: getCssVariable('--third-color').trim(),
//           alert: getCssVariable('--alert-color').trim(),
//           contour: getCssVariable('--contour-color').trim(),
//           background: getCssVariable('--background-color').trim(),
//           surface: getCssVariable('--surface-color').trim(),
//           accent: getCssVariable('--accent-color').trim(),
//           error: getCssVariable('--error-color').trim(),
//           info: getCssVariable('--info-color').trim(),
//           success: getCssVariable('--success-color').trim(),
//           warning: getCssVariable('--warning-color').trim(),

          
//         }
//       },/*
//       dark: {
//         dark: true,
//         colors: {
//           background: '#121212', 
//           surface: '#1E1E1E',    
//           primary: '#FFFFFF',    
//           secondary: '#424242',  
//           accent: '#82B1FF',     
//           error: '#FF5252',      
//           info: '#2196F3',       
//           success: '#4CAF50',    
//           warning: '#FFC107',    
//         }
//       }*/
//     }
//   },

// })



if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('SW registered: ', registration);
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError);
    });
  });
}


  var firebaseConfig = {
    apiKey: "AIzaSyAMyvWK10pOfnQkHxajgNTlEdCH1kM4rzk",
    authDomain: "amichile-app.firebaseapp.com",
    projectId: "amichile-app",
    storageBucket: "amichile-app.appspot.com",
    messagingSenderId: "327694230181",
    appId: "1:327694230181:web:4a22eab482460b732fa34c"
  };
  

  


const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
export { db, collection, addDoc, doc, getDoc, setDoc, getDocs, updateDoc, where, query, serverTimestamp, getStorage, storageRef, getDownloadURL, uploadBytes, deleteDoc, deleteObject, orderBy, writeBatch, runTransaction};
// Create Pinia store...

const pinia = createPinia();
const vueApp = createApp(App);



const vueAppPinia = vueApp.use(pinia);
const authStore = useAuthStore();
authStore.$init(); 
vueAppPinia.use(router)
           .use(vuetify)
           .use(VueGoogleMaps, {
            load: {
              key: 'AIzaSyD0eDFRRqiia4zIJKGmYC650DkjKI2Yz9c', // Use the API key from environment variables
              libraries: 'places', // If you need specific libraries, e.g., places
            },
})
.mount('#app');