<template>
  <v-dialog class="default-dialog" v-model="authStore.showLoginPopup">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="2">
            <v-btn icon @click="authStore.toggleLoginPopup(false)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="10">
            <div class="text-h6">
              Regístrate para acceder a más funcionalidades
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider :thickness="4"></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <div class="text-h6">Desbloquea:</div>
          </v-col>
          <v-col cols="12">
            <div style="text-align: left;">
              <p>1. Acceso al mapa con los reportes de toxinas</p>
              <p>2. Acceso a descargar reportes</p>
              <p>3. Acceso al listado de proveedores</p>
              <p>4. Escribir comentarios en el feed de la comunidad</p>
            </div>
            <v-card-text>Regístrate usando:</v-card-text>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center">
          <v-col cols="8">
            <v-btn block @click="handleLoginWithGoogle">Google</v-btn>
          </v-col>
          <v-col cols="8">
            <v-btn block @click="handleLoginWithEmail"
              >Correo electrónico</v-btn
            >
          </v-col>
          <v-col cols="8">
            <v-btn block @click="continueAsTourist">Seguir como invitado</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useAuthStore } from "@/stores/auth";

export default {
  name: "LoginPopUp",

  setup() {
    const authStore = useAuthStore();

    const handleLoginWithGoogle = async () => {
      await authStore.signInWithGoogle();

      // Ensure authStore.user.uid is set before proceeding
      if (authStore.user.uid) {
        authStore.toggleLoginPopup(false); // Close login popup
      }
      if (authStore.user.uid && !authStore.isAdditionalDataFilled()) {
        authStore.toggleAdditionalDataPopup(true);}
    };

    const continueAsTourist = () => {
      authStore.toggleLoginPopup(false);
    };
    const handleLoginWithEmail = () => {
      authStore.toggleLoginPopup(false);
      authStore.toggleLoginWithEmailPopup(true);
    };

    return {
      handleLoginWithGoogle,
      handleLoginWithEmail,
      continueAsTourist,
      authStore,
    };
  },
};
</script>
<style>
.custom-switch-label .v-label {
  color: black;
}
</style>
