import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from './stores/auth.js'

const HomePage = () => import('./views/HomePage.vue');

const AdminDashboard = () => import('./views/AdminDashboard/AdminDashboard.vue');
const ManageSurveys = () => import('./views/AdminDashboard/ManageSurveys.vue');
const ManageReports = () => import('./views/AdminDashboard/ManageReports.vue');
const ManageUsers = () => import('./views/AdminDashboard/ManageUsers.vue');
const ManageProviders = () => import('./views/AdminDashboard/ManageProviders.vue');
const CreateSurvey = () => import('./views/AdminDashboard/CreateSurvey.vue');
const NuevoEvento = () => import('./views/NewEvent.vue');

const UserDashboard = () => import('./views/UserDashboard/UserDashboard.vue');
const PdfReports = () => import('./views/UserDashboard/PdfReports.vue');
const SurveyList = () => import('./views/UserDashboard/SurveyList.vue');
const ProvidersList = () => import('./views/UserDashboard/ProvidersList.vue');
const DataViewer = () => import('./views/UserDashboard/DataViewer.vue');


const FeedView = () => import('./views/FeedView.vue');

const MapView = () => import('./views/MapView.vue');

// components
const UserInfoForm = () => import('./components/UserInfoForm.vue');
const ResultadoLote = () => import('./components/ResultadoLote.vue');
const HistoricalReport = () => import('./components/HistoricalReport.vue');


const routes = [
  { path: '/',name: 'HomePage',  component: HomePage, meta: { requiresAuth: true, minTier: 'tourist' } },
  { path: '/AdminDashboard',name: 'AdminDashboard',  component: AdminDashboard, meta: { requiresAuth: true, minTier: 'admin' } },
  { path: '/UserInfoForm',name: 'UserInfoForm',  component: UserInfoForm, meta: { requiresAuth: true, minTier: 'free' } },
  { path: '/ManageUsers',name: 'ManageUsers',  component: ManageUsers, meta: { requiresAuth: true, minTier: 'free' } },
  { path: '/ManageProviders',name: 'ManageProviders',  component: ManageProviders, meta: { requiresAuth: true, minTier: 'free' } },
  { path: '/ResultadoLote',name: 'ResultadoLote',  component: ResultadoLote, meta: { requiresAuth: true, minTier: 'free' } },
  { path: '/NuevoEvento',name: 'NuevoEvento',  component: NuevoEvento, meta: { requiresAuth: true, minTier: 'free' } },
  { path: '/FeedView',name: 'FeedView',  component: FeedView, meta: { requiresAuth: true, minTier: 'tourist' } },
  { path: '/MapView',name: 'MapView',  component: MapView, meta: { requiresAuth: true, minTier: 'tourist' } },
  { path: '/PdfReports',name: 'PdfReport',  component: PdfReports, meta: { requiresAuth: true, minTier: 'free' } },
  { path: '/UserDashboard',name: 'UserDashboard',  component: UserDashboard, meta: { requiresAuth: true, minTier: 'tourist' } },
  { path: '/CreateSurvey',name: 'CreateSurvey',  component: CreateSurvey, meta: { requiresAuth: true, minTier: 'admin' } },
  { path: '/ManageSurveys',name: 'ManageSurveys',  component: ManageSurveys, meta: { requiresAuth: true, minTier: 'admin' } },
  { path: '/ManageReports',name: 'ManageReports',  component: ManageReports, meta: { requiresAuth: true, minTier: 'admin' } },
  { path: '/SurveyList',name: 'SurveyList',  component: SurveyList, meta: { requiresAuth: true, minTier: 'tourist' } },
  { path: '/ProvidersList',name: 'ProvidersList',  component: ProvidersList, meta: { requiresAuth: true, minTier: 'tourist' } },
  { path: '/HistoricalReport',name: 'HistoricalReport',  component: HistoricalReport, meta: { requiresAuth: true, minTier: 'free' } },
  { path: '/DataViewer',name: 'DataViewer',  component: DataViewer, meta: { requiresAuth: true, minTier: 'free' } },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  const { requiresAuth, minTier } = to.meta;
  const authStore = useAuthStore(); // Import and use your auth store

  if (requiresAuth) {
    const userTierLevel = authStore.tierLevels[authStore.user.tier];
    const requiredTierLevel = authStore.tierLevels[minTier];

    if (userTierLevel < requiredTierLevel) {
      // User does not have the required tier
      return next({ name: 'Unauthorized' }); // Redirect to an unauthorized page or any other handling
    }
  }
  document.title = to.meta.title || 'Mussel App';


  next(); // Proceed to the route
});

export default router