import { defineStore } from "pinia";
import { db, doc, setDoc, getDoc, getDocs, collection } from "@/main";
import {
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
  GoogleAuthProvider,
  getAuth,
  onAuthStateChanged,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword
} from "firebase/auth";
import router from "@/router";
import { useCounterStore } from "@/stores/counter.js";
import { useDataStore } from "@/stores/data.js";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: {
      // data from google auth
      uid: null,
      email: null,
      name: null,
      photoURL: null,
      // Data asigned by app or admin
      tier: "tourist",
      centrosPorRut: [],
      centrosCreados: [],
      centrosValidados: [],
      ciclosProductivos: [],
      evento_subcollection: null,
      centros_subcollection: null,
      activos_subcollection: null,
      // Data from firebase set up by user
      rut: null,
      razonSocial: null,
      direccion: null,
      telContacto: null,
      comuna: null,
      region: null,
      engorda: false,
      captura: false,
      procesado: false,
    },
    showLoginPopup: false,
    showAdditionalDataPopup: false,
    showLoginWithEmailPopup: false,
    
  }),

  getters: {
    tierLevels: () => ({
      tourist: 1,
      free: 2,
      paid: 3,
      admin: 4,
    }),

  },
  actions: {
    async $init() {

      try {
        const auth = getAuth();
        await setPersistence(auth, browserLocalPersistence); // Enable session persistence
        this.initAuthStateListener();
        this.showLoginPopupAfterDelay();
      } catch (error) {
        console.error("Error during initialization:", error);
      }
    },

    
    async fetchUserData(uid = this.user.uid) {
      if (!uid) return;
      try {
        const userRef = doc(db, "Usuarios", uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (uid === this.user.uid) { // Only update the store if it's the current user
            this.$patch({ user: { ...this.user, ...userData } });
            console.log("User data fetched from collection Usuarios on Firestore and set on state User of pinia auth Store, userData:", userData);
          }
          return userData;
        } else {
          console.log("No user data found on collection Usuarios on Firestore for UID:", uid);
        }
      } catch (error) {
        console.error("Error fetching user data from collection Usuarios on Firestore:", error);
      }
    },
    initAuthStateListener() {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          // User is signed in, update the store's user state
          console.log("User is signed in:", user);
          this.user.uid = user.uid;
          this.user.email = user.email;
          this.user.name = user.displayName;
          this.user.photoURL = user.photoURL;
          await this.fetchUserData(user.uid);
          await this.syncUserData(user.uid, this.user);
        } else {
          // User is signed out, reset the store's user state or handle accordingly
          console.log("User is signed out");
          this.user = { uid: null, tier: "tourist" }; // Reset user state or adjust as needed
        }
      });
    },


    async handleAuthResult(user) {
      if (!user) {
        throw new Error("User authentication failed.");
      }
      this.user.uid = user.uid;
      this.user.email = user.email;
      this.user.name = user.displayName || user.email;
      this.user.photoURL = user.photoURL;

      await this.fetchUserData(user.uid);
      alert("Sesión iniciada correctamente.");

      if (!this.isAdditionalDataFilled()) {
        this.toggleAdditionalDataPopup(true);
      } else {
        router.push("/HomePage").catch((error) => {
          console.error("Error navigating to HomePage:", error);
          const counterStore = useCounterStore();
          counterStore.logError(error);
        });
      }
    },
    async signInWithGoogle() {
      try {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        await setPersistence(auth, browserLocalPersistence); // Enable session persistence
        const result = await signInWithPopup(auth, provider);
        await this.handleAuthResult(result.user);
      } catch (error) {
        console.error("Error during Google sign-in:", error);
        const counterStore = useCounterStore();
        counterStore.logError(error);
        throw error;
      }
    },


    async signInWithEmailAndRequestData(email, password) {
      try {
        const result = await this.signInWithEmail(email, password);
        console.log("Usuario autenticado:", result);
        if (!result) {
          throw new Error("No se pudo obtener la información del usuario.");
        }
        this.user.uid = result.uid;
        this.user.email = result.email;

        console.log("Usuario autenticado:", this.user);
        console.log("UID del usuario autenticado:", this.user.uid);
      } catch (error) {
        console.error("Error al iniciar sesion:", error);
      }
    },

    async signInWithEmail(email, password) {
      try {
        const auth = getAuth();
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        await this.handleAuthResult(userCredential.user);
      } catch (error) {
        console.error("Error logging in with email:", error);
        alert("Ha ocurrido un error al iniciar sesión, favor confirmar correo y contraseña");
      }
    },
    
    
    
    async signUpWithEmail(email, password) {
      try {
        const auth = getAuth();
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        // Initialize new user data if needed
        await this.syncUserData(userCredential.user.uid, this.user);
        await this.handleAuthResult(userCredential.user);
        alert("Cuenta creada exitosamente.");
      } catch (error) {
        console.error("Error creating user with email:", error);
        let errorMessage = "Se ha producido un error al intentar crear su cuenta.";
        if (error.code) {
          switch (error.code) {
            case "auth/invalid-email":
              errorMessage = "La direccion de correo electronico no es valido, favor intente nuevamente.";
              break;
            case "auth/email-already-in-use":
              errorMessage = "La direccion de correo ya existe, favor intente nuevamente.";
              break;
            case "auth/weak-password":
              errorMessage = "La contrasena es muy debil, favor prueba con una diferente.";
              break;
            default:
              errorMessage = error.message; // Fallback to generic Firebase error message
              break;
          }
        }
        alert(errorMessage); // Display a specific error message based on the error code
      }
    },

    async setTier(tier, uid) {
      const userRef = doc(db, "Usuarios", uid);
      await setDoc(userRef, { tier: tier }, { merge: true });
      console.log("Tier set to", tier);
      this.fetchUserData();
      
    },
    async syncUserData(uid = this.user.uid, userData = this.user) {
      const dataStore = useDataStore();
      await dataStore.fetchCentrosPerRut(this.user.rut);
      const userRef = doc(db, "Usuarios", uid);
      await setDoc(userRef, userData, { merge: true });
      this.fetchUserData();
    },

    async fetchProviders() {
      try {
        const providersCollectionRef = collection(db, "Proveedores");
        const querySnapshot = await getDocs(providersCollectionRef);
        const providersList = [];
        querySnapshot.forEach((doc) => {
          providersList.push({
            id: doc.id, // Assuming each user has an ID
            ...doc.data() // Spreading all other user data
          });
        });
        return providersList; // This returns an array of user objects
      } catch (error) {
        console.error("Error fetching users:", error);
        return []; // Return an empty array if there's an error
      }
    },

    async pushProviderData(rut, providerData) {
      const userRef = doc(db, "Proveedores", rut);
      await setDoc(userRef, providerData, { merge: true });
    },
    isAdditionalDataFilled() {
      // Check if all required additional data fields are not null
      const userData = this.user;
      return (
        userData.rut !== null &&
        userData.razonSocial !== null &&
        userData.direccion !== null &&
        userData.telContacto !== null &&
        userData.comuna !== null &&
        userData.region !== null
      );
    },
    UserSignOut() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Directly reset the user state to default values here
          this.user = {
            uid: null,
            email: null,
            name: null,
            photoURL: null,
            tier: "tourist",
            rut: null,
            razonSocial: null,
            direccion: null,
            telContacto: null,
            comuna: null,
            region: null,
            engorda: false,
            captura: false,
            procesado: false,
            centrosPorRut: [],
          };
          console.log("User signed out and state reset");
        })
        .catch((error) => {
          console.error("Error signing out: ", error);
        });
    },
    isTourist() {
      this.fetchUserData();
      return this.user.tier === "tourist";
    },
    async showLoginPopupAfterDelay() {
      setTimeout(() => {
        if (this.isTourist()) {
          this.toggleLoginPopup(true);
        }
      }, 5000);
    },
    toggleLoginPopup(value) {
      this.showLoginPopup = value;
    },
    toggleAdditionalDataPopup(value) {
      this.showAdditionalDataPopup = value;
    },

    toggleLoginWithEmailPopup(value) {
      this.showLoginWithEmailPopup = value;
    },

    setAdditionalData({ rut, razonSocial, direccion, telContacto, comuna, region, captura, engorda, procesado }) {
      this.user.rut = rut;
      this.user.razonSocial = razonSocial;
      this.user.direccion = direccion;
      this.user.telContacto = telContacto;
      this.user.comuna = comuna;
      this.user.region = region;
      this.user.captura = captura;
      this.user.engorda = engorda;
      this.user.procesado = procesado;
      this.syncUserData(this.user.uid, this.user);
      router.push("/HomePage").catch((err) => {
        console.error("Error navigating to HomePage:", err);
      });
      if (this.isAdditionalDataFilled() && this.isTourist()) {
        this.setTier("free", this.user.uid);
      }
    },

    installPWA() {
      if (window.deferredPrompt) {
        const deferredPrompt = window.deferredPrompt;
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
          } else {
            console.log("User dismissed the A2HS prompt");
          }
          window.deferredPrompt = null;
        });
      } else {
        console.log("No install prompt available. Make sure it's captured in beforeinstallprompt event.");
      }
    },
    
    validateRut(value, user) {
      if (!value) return "El RUT es requerido.";
      // Regex pattern to accept both XX.XXX.XXX-X and XXXXXXXX-X formats
      const pattern = /^(\d{1,3}(?:\.\d{3}){2}|\d{7,8})-[\dkK]$/;
      if (!pattern.test(value)) {
        return "Formato de RUT inválido. Debe ser como XX.XXX.XXX-X o XXXXXXXX-X";
      }

      // Normalize the RUT to XXXXXXXX-X format
      const cleanRut = value.replace(/\./g, "").toUpperCase();
      const rutWithoutDv = cleanRut.split("-")[0];
      const dv = cleanRut.split("-")[1];

      // Calculate the RUT's check digit
      const digits = rutWithoutDv.split("").reverse();
      let total = 0;
      for (let i = 0; i < digits.length; i++) {
        total += parseInt(digits[i], 10) * ((i % 6) + 2);
      }
      const calculatedDV = 11 - (total % 11);
      const validDV = calculatedDV === 11 ? '0' : (calculatedDV === 10 ? 'K' : calculatedDV.toString());

      if (validDV === dv) {
        user.rut = `${rutWithoutDv}-${dv}`;  // Update the user.rut with normalized RUT
        return "RUT Correct";  // Return success message
      } else {
        return "RUT inválido.";  // RUT did not validate
      }
    }
  },
});
