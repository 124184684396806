<template>
  <v-bottom-navigation v-model="activeBottom" elevation="0">
    <v-row no-gutters style="justify-content: space-evenly;">
      <v-col
        v-for="(item, index) in filteredMenuItemsBottom"
        :key="index"
        style="padding-left: 0px; padding-right: 0px; padding-top: 0px; margin-top: 0px"
        cols="auto"
      >
        <v-btn :to="item.link" @click="activeBottom = index" :class="{ 'active-btn': activeBottom === index }" block>
          <svg-icon type="mdi" :path="item.icon"></svg-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-bottom-navigation>
</template>

<script>
import { ref, computed } from "vue";
import { useCounterStore } from "@/stores/counter";
import SvgIcon from "@jamescoyle/vue-icon";

export default {
  name: "BottomMenu",
  components: {
    SvgIcon,
  },
  setup() {
    const counterStore = useCounterStore();
    const value = ref(0); // Adjust based on your default active index
    const filteredMenuItemsBottom = computed(() => counterStore.filteredMenuItemsBottom); // Make sure this computed property exists in your store

    return {
      activeBottom: value,
      filteredMenuItemsBottom,
    };
  },
};
</script>
<style scoped>

.v-bottom-navigation {
  height: 50px;
  justify-content: center;
}
.v-btn {
  border-top: 3px solid transparent;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  color: var(--primary-color);
  height: 56px; 
  padding-top: 0px; 
  padding-bottom: 0px;
}
.active-btn { 
  background-color: transparent; 
  border-top-color: var(--primary-color); 
}
</style>
